import React from 'react';
import './../Styles/NotificationModal.css'; // Add a CSS file for styling
import { FaExclamationCircle, FaTimes } from 'react-icons/fa'; // Importing icons

const NotificationModal = ({ message, onClose }) => {
  return (
    <div className="NotificationModal-modal-overlay">
      <div className="NotificationModal-modal-content">
        <FaExclamationCircle className="modal-icon" />
        <p className="modal-message">{message}</p>
        <FaTimes className="modal-close-icon" onClick={onClose} /> {/* Close icon on the top-right */}
      </div>
    
    </div>
  );
};

export default NotificationModal;
