// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import './../Styles/Register.css';
// import logo from './../Images/tsqs-hm-ft-logo.gif';
// import { Link, useNavigate } from 'react-router-dom';
// const apiUrl = process.env.REACT_APP_API_URL;

// const Register = () => {
//   const [username, setUsername] = useState('');
//   const [password, setPassword] = useState('');
//   const [employeeId, setEmployeeId] = useState(''); // New state for Employee ID
//   const [registrationStatus, setRegistrationStatus] = useState('');

//   const handleRegister = (e) => {
//     e.preventDefault();

//     // Validation to check if both fields are filled
//     if (!username || !password || !employeeId) {
//       setRegistrationStatus(' "All fields  are required"');
//       return;
//     }

//     // Validation to check if the email address is from tsqsinc.com domain
// const emailDomain = username.split('@')[1];
// if (emailDomain !== 'tsqsinc.com') {
//   setRegistrationStatus('Invalid email address. Only tsqsinc.com email addresses are allowed.');
//   return;
// }

//     axios.post(`${apiUrl}/register`, { username, password, employeeId })
//       .then(response => {
//         console.log(response.data);
//         setRegistrationStatus('Registration was successful');
//       })
//       .catch(error => {
//         console.error('Error:', error);
//         if (error.response) {
//           setRegistrationStatus(`Registration failed: ${error.response.data}`);
//         } else if (error.request) {
//           setRegistrationStatus('Registration failed: No response from server');
//         } else {
//           setRegistrationStatus(`Registration failed: ${error.message}`);
//         }
//       });
//   };

//   useEffect(() => {
//     if (registrationStatus) {
//       const timer = setTimeout(() => {
//         setRegistrationStatus('');
//       }, 5000); // Close the notification after 5 seconds

//       return () => clearTimeout(timer); // Cleanup the timer on unmount
//     }
//   }, [registrationStatus]);

//   return (
//     <div className="Register-page">
//       <div className="Header">
//       <img src={logo} alt="Company Logo" className="Logo" />
//       </div>
//       <div className="Header1"> 
//       <h1 className="Main-heading">PERFORMANCE MANAGEMENT SYSTEM</h1>
//       </div>
//     <div className="Register">
//     {registrationStatus && (
//         <div className="Notification-overlay">
//           <p>{registrationStatus}</p>
//           </div>
//       )}
//       <h2>REGISTER</h2>
//       <form onSubmit={handleRegister}>
//         <div>
//         <div className="emaillogin">
//           <label>Email:</label>
//           <input
//             type="email"
//             value={username}
//             onChange={(e) => setUsername(e.target.value)}
//           />
//           </div>
//         </div>
//         <div className='employeeId'>
//             <label>Employee ID:</label> {/* New Employee ID input field */}
//             <input
//               type="text"
//               value={employeeId}
//               onChange={(e) => setEmployeeId(e.target.value)}
//             />
//           </div>
//         <div className='password'>
//           <label>Password:</label>
//           <input
//             type="password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//           />
//         </div>
//         <button type="submit" className='login-btn'>Register</button>
//       </form>
//       <p className="login-p">
//          "Return to Login Page, <Link to="/" style={{ color: 'orange' }}>Click here</Link> to Sign in"
//         </p>
//       {registrationStatus && (
//         <div className="Notification-overlay">
//           <p>{registrationStatus}</p>
//           </div>
//       )}
//     </div>
//     </div>
//   );
// }

// export default Register;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './../Styles/Register.css';
import logo from './../Images/tsqs-hm-ft-logo.gif';
import { Link, useNavigate } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL;

const Register = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [employeeId, setEmployeeId] = useState(''); // New state for Employee ID
  const [registrationStatus, setRegistrationStatus] = useState('');
  const [selectedProjectId, setSelectedProjectId] = useState('');
  const [projects, setProjects] = useState([]); // State to store projects
  console.log('projectID',selectedProjectId);
  const handleRegister = async (e) => {
    e.preventDefault();

    const registrationData = {
      username,
      password,
      employeeId,
      projectId: selectedProjectId, // Ensure this is set correctly
    };
    // Validation to check if all fields are filled
    if (!username || !password || !employeeId || !selectedProjectId) {
      setRegistrationStatus('All fields are required');
      return;
    }

    // Validation to check if the email address is from tsqsinc.com domain
    const emailDomain = username.split('@')[1];
    if (emailDomain !== 'tsqsinc.com') {
      setRegistrationStatus('Invalid email address. Only tsqsinc.com email addresses are allowed.');
      return;
    }

    try {
      // Step 1: Validate employee existence in organization
      const validationResponse = await axios.post(`${apiUrl}/validate-employee`, {
        employeeId,
        email: username,
        projectId: selectedProjectId
      });
       
      if (validationResponse.data.valid) {
        // Step 2: Proceed with registration if the employee is valid
        const registerResponse = await axios.post(`${apiUrl}/register`,registrationData);
        
        setRegistrationStatus('Registration was successful');
      } else {
        // If the employee is not found in the organization database
        setRegistrationStatus('Employee ID or Email does not exist in our organization.');
        console.log('Validation failed: Employee not found');
      }
    } catch (error) {
      if (error.response) {
        // Log the entire error response for debugging
        console.error('Error response:', error.response);
        
        // Check for user already exists error
        if (error.response.status === 400 && error.response.data.error === 'User already exists.') {
          setRegistrationStatus('User already exists. Please use a different username.');
        } else {
          setRegistrationStatus(`Registration failed: ${error.response.data}`);
        }
      } else if (error.request) {
        setRegistrationStatus('Registration failed: No response from server');
      } else {
        setRegistrationStatus(`Registration failed: ${error.message}`);
      }
      console.error('Registration error:', error);
    }
  };

  useEffect(() => {
    if (registrationStatus) {
      const timer = setTimeout(() => {
        setRegistrationStatus('');
      }, 5000); // Close the notification after 5 seconds

      return () => clearTimeout(timer); // Cleanup the timer on unmount
    }
  }, [registrationStatus]);


  useEffect(() => {
    // Fetch projects from the database when the component mounts
    const fetchProjects = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/fetch-projects`); // Adjust the endpoint as necessary
        setProjects(response.data);
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    fetchProjects();
  }, []);

  return (
    <div className="Register-page">
      <div className="Header">
        <img src={logo} alt="Company Logo" className="Logo" />
      </div>
      <div className="Header1"> 
        <h1 className="Main-heading">PERFORMANCE MANAGEMENT SYSTEM</h1>
      </div>
      <div className="Register">
        {registrationStatus && (
          <div className="Notification-overlay">
            <p>{registrationStatus}</p>
          </div>
        )}
        <h2>REGISTER</h2>
        <form onSubmit={handleRegister}>
          <div>
            <div className="emaillogin">
              <label>Email:</label>
              <input
                type="email"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
          </div>
          <div className='employeeId'>
            <label>Employee ID:</label> {/* New Employee ID input field */}
            <input
              type="text"
              value={employeeId}
              onChange={(e) => setEmployeeId(e.target.value)}
            />
          </div>
          <div className='password'>
            <label>Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className='project-select'>
            <label>Select Project:</label>
            <select 
              value={selectedProjectId} 
              onChange={(e) => setSelectedProjectId(e.target.value)}
             >
              <option value="">Select a project</option>
              {projects.map(project => (
                <option key={project.id} value={project.id}>
                  {project.project_name} {/* Adjust according to your project object structure */}
                </option>
              ))}
            </select>
          </div>
          <button type="submit" className='login-btn'>Register</button>
        </form>
        <p className="login-p">
          "Return to Login Page, <Link to="/" style={{ color: 'orange' }}>Click here</Link> to Sign in"
        </p>
      </div>
    </div>
  );
};

export default Register;

