import React, { useEffect, useState, useRef } from 'react';
import './../Styles/Admin.css';
import logo from './../Images/tsqs-hm-ft-logo.gif';
import axios from 'axios';
import CreateGoal from './CreateGoal';
import AssignGoal from './AssignGoal';
import lottie from 'lottie-web';
import Lottie from 'lottie-react';
import comingSoonAnimation from './../ani/comingsoon.json';
import Notifications from './../Components/Notifications'; // Import Notifications component
import EmployeeDetailsDialog from './EmployeeDetailsDialog';
import { TailSpin } from 'react-loader-spinner'; // Using react-loader-spinner
import MESection from './MESection'; // Import the M&E component
import ProfileDialog from './ProfileDialog';
const apiUrl = process.env.REACT_APP_API_URL;


const MainManager = () => {
    const [employees, setEmployees] = useState([]);
    const [showCreateGoalDialog, setShowCreateGoalDialog] = useState(false);
    const [showAssignGoalDialog, setShowAssignGoalDialog] = useState(false);
    const [managerInfo, setManagerInfo] = useState({ full_name: '', job_title: '' });
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [employeeDetails, setEmployeeDetails] = useState([]);
    const [finalRating, setFinalRating] = useState('');
    const [submissionSuccess, setSubmissionSuccess] = useState(false); // State for submission success message
    const [currentSection, setCurrentSection] = useState('employees');
    const reportsContainer = useRef(null);
    const projectsContainer = useRef(null);
    const performanceContainer = useRef(null);
    const updatesContainer = useRef(null);
    const [selectedQuarter, setSelectedQuarter] = useState('Q1 2024'); // Default to Q1 or any quarter you prefer
    const [noGoalsMessage, setNoGoalsMessage] = useState('');
    const [error, setError] = useState(null);
    //for goal assigning
    const [goals, setGoals] = useState([]);
    const [selectedGoals, setSelectedGoals] = useState([]);
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [goalsDropdownOpen, setGoalsDropdownOpen] = useState(false);
    const [employeesDropdownOpen, setEmployeesDropdownOpen] = useState(false);
    const [showUpdateMessage, setShowUpdateMessage] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredEmployees, setFilteredEmployees] = useState([]);
    const [loading, setLoading] = useState(false); // Loading state
    const goalsDropdownRef = useRef(null);
    const employeesDropdownRef = useRef(null);
    //profile
    const [isProfileDialogOpen, setProfileDialogOpen] = useState(false); // State for profile dialog
    



    useEffect(() => {
        const fetchData = async () => {
            try {
                const goalsResponse = await axios.get(`${apiUrl}/api/goals`);
                console.log('Fetched goals:', goalsResponse.data);  // Debugging
                setGoals(goalsResponse.data);
                const employeesResponse = await axios.get(`${apiUrl}/api/employees`);
                console.log('Fetched employees:', employeesResponse.data);  // Debugging
                setEmployees(employeesResponse.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (goalsDropdownRef.current && !goalsDropdownRef.current.contains(event.target)) {
                setGoalsDropdownOpen(false);
            }
            if (employeesDropdownRef.current && !employeesDropdownRef.current.contains(event.target)) {
                setEmployeesDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleCheckboxChange = (setStateFunction, value) => {
        setStateFunction(prev => {
            if (prev.includes(value)) {
                return prev.filter(item => item !== value);
            } else {
                return [...prev, value];
            }
        });
    };

    const handleAssignGoal = async () => {
        if (selectedGoals.length === 0 || selectedEmployees.length === 0) {
            alert('Please select at least one goal and one employee.');
            return;
        }

        try {
            console.log('Assigning goals:', selectedGoals, 'to employees:', selectedEmployees);
            await axios.post(`${apiUrl}/api/assign-goal`, {
                goalIds: selectedGoals,
                employeeIds: selectedEmployees
            });
            setShowUpdateMessage(true);
            setTimeout(() => {
                setShowUpdateMessage(false);
            }, 10000);
        } catch (error) {
            console.error('Error assigning goals:', error);
            alert('Error assigning goals');
        }
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    useEffect(() => {
        // Load and play the Lottie animations for different sections
        lottie.loadAnimation({
            container: reportsContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: comingSoonAnimation,
        });

        lottie.loadAnimation({
            container: projectsContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: comingSoonAnimation,
        });

        lottie.loadAnimation({
            container: performanceContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: comingSoonAnimation,
        });

        lottie.loadAnimation({
            container: updatesContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: comingSoonAnimation,
        });

       
        return () => {
            lottie.destroy();
        };
    }, []);


    //newly addedd 22-08:1:37
    useEffect(() => {
        const managerId = localStorage.getItem('employeeId');
        if (!managerId) {
            console.error('Manager ID not found in local storage.');
            return;
        }

        // Fetch manager info
        axios.get(`${apiUrl}/api/manager-info/${managerId}`)
            .then(response => {
                setManagerInfo(response.data);
                console.log('Manager Info:', response.data);


               const isMainManager = response.data.is_main_manager;
               console.log('isMainManager:', isMainManager); // Log to verify value

                // Fetch employees based on manager type
                axios.get(`${apiUrl}/api/employees`, {
                    params: {
                        managerId: managerId,
                        isMainManager: isMainManager // Make sure to pass this correctly
                    }
                })
                
                .then(response => setEmployees(response.data))
                .catch(error => setError('Error fetching employee data: ' + error));
            })
            .catch(error => setError('Error fetching manager info: ' + error));
    }, []);


    const handleCreateGoal = () => {
        setShowCreateGoalDialog(true);
    };

    // Handler to close both dialogs
    const closeDialogs = () => {
        setShowAssignGoalDialog(false);
        setShowCreateGoalDialog(false);
    };

    // Retrieve managerId from localStorage
    const managerId = localStorage.getItem('employeeId');


    const handleViewDetails = (employee, quarter) => {

        setEmployeeDetails([]); // Clear previous results
        setNoGoalsMessage(''); // Clear any previous message
        setSelectedEmployee(employee);
        console.log('Fetching details for employee number:', employee.employee_number); // Log the request
       
        axios.get(`${apiUrl}/api/employee-admin-goals/${employee.employee_number}/${encodeURIComponent(quarter)}`)
            .then(response => {
                // console.log('Response data:', response.data); // Log the response
                // setEmployeeDetails(response.data);
                if (response.data.length === 0) {
                    // No goals found for the selected quarter
                    console.log("details",quarter);
                    setEmployeeDetails(null);
                    setNoGoalsMessage(`No goals found for ${employee.employee_number} in ${quarter}.`);
                } else {
                    setEmployeeDetails(response.data);
                    setNoGoalsMessage(''); // Clear any previous message
                }

            })
            .catch(error => {
                console.error('Error fetching employee details:', error);
            });
    };


    const handleFinalRatingChange = (rating) => {
        setFinalRating(rating);
    };

const handleReviewerRatingChange = (goalId, newRating) => {
    setEmployeeDetails(prevDetails =>
        prevDetails.map(detail =>
            detail.goal_id === goalId ? { ...detail, reviewer_rating: newRating } : detail
        )
    );
};

const handleSubmit = async () => {
    setLoading(true); // Start loading
    if (!selectedEmployee) return;
    console.log('submitting for:', selectedEmployee.employee_number);

    try {
        // Submit individual goal reviews
        const goalReviewsData = {
            goals: employeeDetails.map(detail => ({
                goalId: detail.goal_id,
                reviewerRating: detail.reviewer_rating,
            })),
            employeeId: selectedEmployee.employee_number,
        };

        const goalReviewsResponse = await axios.post(
            `${apiUrl}/api/submit-goal-reviews`,
            goalReviewsData
        );
        console.log("Goal reviews response:", goalReviewsResponse);

        // Submit final rating and reviewer comment
        const finalReviewData = {
            employeeId: selectedEmployee.employee_number,
            finalRating,
            reviewerComment: employeeDetails[0]?.reviewer_comment,
            quarter: selectedQuarter // Add the selected quarter here
        };

        const finalReviewResponse = await axios.post(
            `${apiUrl}/api/submit-final-review`,
            finalReviewData
        );

        console.log('Final review submitted:', finalReviewResponse.data);
        console.log('Goal reviews submitted:', goalReviewsResponse.data);
        // setSelectedEmployee(null);
        setLoading(false);   // Stop loading
        setSubmissionSuccess(true);

        // Reset the selected employee and success message after a delay
        setTimeout(() => {
            // setSelectedEmployee(null);
            setSubmissionSuccess(false);
        }, 6000); // 10  seconds delay
    } catch (error) {
        console.error('Error submitting data:', error);
        setLoading(false); // Stop loading on error
    }
};
 

//profile handling
const handleOpenProfileDialog = () => {
    setProfileDialogOpen(true);
};

const handleCloseProfileDialog = () => {
    setProfileDialogOpen(false);
};



    const renderDetailsDialog = () => {
        if (!selectedEmployee) return null;

        return (
            <div className="details-dialog-overlay">
                <div className="details-dialog">
                    <h2>Details of {selectedEmployee.full_name}</h2>
                    <div className="quarter-selector">
                    <label htmlFor="quarter">Select Quarter:</label>
                    <select
                        id="quarter"
                        value={selectedQuarter}
                        onChange={(e) => {
                            setSelectedQuarter(e.target.value);
                            handleViewDetails(selectedEmployee, e.target.value);
                        }}
                    >
                        <option value="Q1 2024">Q1 2024</option>
                        <option value="Q2 2024">Q2 2024</option>
                        <option value="Q3 2024">Q3 2024</option>
                        <option value="Q4 2024">Q4 2024</option>
                    </select>
                </div>

                    <div className="employee-summary">
                        <div className="summary-item">
                            <p><strong>Full Name:</strong> {selectedEmployee.full_name}</p>
                        </div>
                        <div className="summary-item">
                            <p><strong>Location:</strong> {selectedEmployee.location}</p>
                        </div>
                        <div className="summary-item">
                            <p><strong>Job Title:</strong> {selectedEmployee.job_title}</p>
                        </div>
                        <div className="summary-item">
                            <p><strong>Department:</strong> {selectedEmployee.department}</p>
                        </div>
                    </div>
                    {employeeDetails.length > 0 ? (
                        <>
                            <table className="details-table">
                                <thead>
                                    <tr>
                                        {/* <th>Goal ID</th> */}
                                        <th>Goal Description</th>
                                        <th>Self Assessment</th>
                                        <th>Self Rating</th>
                                        <th>Communicated</th>
                                        <th>Attachments</th>
                                        <th>Reviewer Rating</th>
                                        <th rowSpan={employeeDetails.length + 1}>Final Rating</th> {/* New column header with row span */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {employeeDetails.map((detail, index) => (
                                        <tr key={detail.id}>
                                            {/* <td className="tabledisabled">{detail.goal_id}</td> */}
                                            <td className="tabledisabled">{detail.goal_description}</td>
                                            <td className="tabledisabled">{detail.self_assessment || 'N/A'}</td>
                                            <td className="tabledisabled">{detail.self_rating || 'N/A'}</td>
                                            <td className="tabledisabled">{detail.communicated ? 'Yes' : 'No'}</td>
                                            <td className="tabledisabled">
                                                {detail.attachments ? (
                                                    <a href={detail.attachments} target="_blank" style={{ color: '#ed9c22' }}  rel="noopener noreferrer">
                                                        View Attachment
                                                    </a>
                                                ) : 'None'}
                                            </td>
                                            <td>
                                            <select
                                                    value={detail.reviewer_rating || ''}
                                                    onChange={(e) => handleReviewerRatingChange(detail.goal_id, e.target.value)}
                                                >
                                                    <option value="" disabled>Select</option>
                                                    {[1, 2, 3, 4, 5].map(rating => (
                                                        <option key={rating} value={rating}>{rating}</option>
                                                    ))}
                                                </select>
                                            </td>
                                            {index === 0 && (
                                                <td rowSpan={employeeDetails.length}>
                                                    <select 
                                                        value={finalRating} 
                                                        onChange={(e) => handleFinalRatingChange(e.target.value)}
                                                    >
                                                        <option value="" disabled>Select</option>
                                                        {[1, 2, 3, 4, 5].map(rating => (
                                                            <option key={rating} value={rating}>{rating}</option>
                                                        ))}
                                                    </select>
                                                </td>
                                            )}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="reviewer-comment-section">
                                <strong>Reviewer Comment:</strong>
                                <textarea 
                                    value={employeeDetails[0]?.reviewer_comment || ''} 
                                    onChange={(e) => setEmployeeDetails(prevDetails => {
                                        const updatedDetails = [...prevDetails];
                                        updatedDetails[0].reviewer_comment = e.target.value;
                                        return updatedDetails;
                                    })}
                                />
                            </div>
                        </>
                    ) : (
                        <p>No goals found for this employee.</p>
                    )}
                      {submissionSuccess && (
                        <div className={`submission-success-message ${submissionSuccess ? 'visible' : ''}`}>
                            Submitted successfully!
                        </div>
                    )}
                     {/* Display loading spinner and text */}
                     {loading && (
                         <div className="overlay-loading">
                         <div className="overlay-content-loading">
                         <TailSpin height="40" width="40" color="#00BFFF" />
                          <span className="loading-text-loading">Submitting Review...</span>
                         </div>
                         </div>
                      )}

                    <button onClick={() => setSelectedEmployee(null)}>Close</button>
                    <button onClick={handleSubmit}>Submit</button>
                </div>
            </div>
        );
    };

    return (
        <div className="admin-page">
            <aside className="sidebar">
                <div className="logo-container">
                    <img src={logo} alt="Company Logo" className="logo" />
                </div>
                <nav className="nav-menu">
                    <a href="#" className={`nav-item ${currentSection === 'employees' ? 'active' : ''}`} onClick={() => setCurrentSection('employees')}>Employees</a>
                    <a href="#" className={`nav-item ${currentSection === 'me' ? 'active' : ''}`} onClick={() => setCurrentSection('me')}>Manager View</a>
                    <a href="#" className={`nav-item ${currentSection === 'reports' ? 'active' : ''}`} onClick={() => setCurrentSection('reports')}> Reviewed Reports</a>
                    <a href="#" className={`nav-item ${currentSection === 'projects' ? 'active' : ''}`} onClick={() => setCurrentSection('projects')}>Project Reports</a>
                    {/* <a href="#" className={`nav-item ${currentSection === 'performance' ? 'active' : ''}`} onClick={() => setCurrentSection('performance')}>Performance Scale</a> */}
                    <a href="#" className={`nav-item ${currentSection === 'updates' ? 'active' : ''}`} onClick={() => setCurrentSection('updates')}>Updates</a>
                </nav>
            </aside>
            <main className="main-content">
                <header className="main-header">
                    <div onClick={handleOpenProfileDialog} className="profile-icon">S</div>
                    <div className="profile-info">
                        <div className="profile-name"onClick={handleOpenProfileDialog}>{managerInfo.full_name}</div>
                        <div className="profile-role"onClick={handleOpenProfileDialog}>{managerInfo.job_title}</div>
                    </div>
                </header>
                <div className="welcome-section">
                    <h1>Hi {managerInfo.full_name}...</h1>
                </div>
                {currentSection === 'employees' && (
                <div className="employee-list-section">
                    <div className="employee-controls">
                        <div className="controls-left">
                            <h2>List of Employees</h2>
                            <div className="goals-dropdown">
                                <button className="dropbtn">Goals ▼</button>
                                <div className="goals-dropdown-content">
                                    <a href="#" onClick={() => setShowAssignGoalDialog(true)}>Assign Goals</a>
                                    <a href="#" onClick={handleCreateGoal}>Create Goals</a>
                                </div>
                            </div>
                            {showAssignGoalDialog && (
                                         <AssignGoal
                                          managerId={managerId}  // Pass managerId to AssignGoal
                                          onClose={closeDialogs} // Pass close handler to AssignGoal
                                           />
            )}
                        </div>
                        <div className="controls-right">
                            <input type="text" placeholder="Search..." className="search-bar"  onChange={handleSearchChange} />
                            <button className="sort-button">Filter</button>
                        </div>
                    </div>
                    
                  
                    <div className="employee-table-wrapper">
                        <table className="employee-table">
                            <thead>
                                <tr>
                                    <th>Employee Number</th>
                                    <th>Full Name</th>
                                    <th>Job Title</th>
                                    <th>Department</th>
                                    <th>Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                {employees.map(employee => (
                                    <tr key={employee.employee_number}>
                                        <td>{employee.employee_number}</td>
                                        <td>{employee.full_name}</td>
                                        <td>{employee.job_title}</td>
                                        <td>{employee.department}</td>
                                        <td>
                                            <button className="details-button" onClick={() => handleViewDetails(employee)}>Details</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                 )}
                 
                 {currentSection === 'me' && (
                   <div className="me-section">
                  <MESection /> {/* Render M&E section */}
                  </div>
                  )}

                {currentSection === 'reports' && (
                    <div >
                       Reports COMING SOON...
                          <Lottie animationData={comingSoonAnimation} className="card-animation" />
                    </div>
                )
                }
                
                {currentSection === 'projects' && <div>
                    Projects COMING SOON...
                    <Lottie animationData={comingSoonAnimation} className="card-animation" />
                    </div>}

                {currentSection === 'performance' && <div>
                    Performance COMING SOON...
                    <Lottie animationData={comingSoonAnimation} className="card-animation" /></div>}

                {currentSection === 'updates' && (
                    <div className="updates-section">
                        {/* Render Notifications Component when 'Updates' is selected */}
                        <Notifications managerId={managerId} />
                    </div>
                )}
              

            </main>
            {showCreateGoalDialog && <CreateGoal onClose={() => setShowCreateGoalDialog(false)} />}
            {/* {showAssignGoalDialog && <AssignGoal onClose={() => setShowAssignGoalDialog(false)} employees={employees} />} */}
            {showAssignGoalDialog && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h2>Assign Goals</h2>
                        <div className="form-group">
                            <label>Select Goals:</label>
                            <div className="dropdown-container" ref={goalsDropdownRef}>
                                <button
                                    className="dropdown-button"
                                    onClick={() => setGoalsDropdownOpen(prev => !prev)}
                                >
                                    {selectedGoals.length === 0 ? 'Select Goals' : `Selected Goals (${selectedGoals.length})`}
                                </button>
                                {goalsDropdownOpen && (
                                    <div className="dropdown-menu">
                                        {goals.map(goal => (
                                            <div key={goal.id} className="checkbox-item">
                                                <input
                                                    type="checkbox"
                                                    id={`goal-${goal.id}`}
                                                    checked={selectedGoals.includes(goal.id)}
                                                    onChange={() => handleCheckboxChange(setSelectedGoals, goal.id)}
                                                />
                                                <label htmlFor={`goal-${goal.id}`}>{goal.goal_description}</label>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Select Employees:</label>
                            <div className="dropdown-container" ref={employeesDropdownRef}>
                                <button
                                    className="dropdown-button"
                                    onClick={() => setEmployeesDropdownOpen(prev => !prev)}
                                >
                                    {selectedEmployees.length === 0 ? 'Select Employees' : `Selected Employees (${selectedEmployees.length})`}
                                </button>
                                {employeesDropdownOpen && (
                                    <div className="dropdown-menu">
                                        {employees.map(employee => (
                                            <div key={employee.employee_number} className="checkbox-item">
                                                <input
                                                    type="checkbox"
                                                    id={`employee-${employee.employee_number}`}
                                                    checked={selectedEmployees.includes(employee.employee_number)}
                                                    onChange={() => handleCheckboxChange(setSelectedEmployees, employee.employee_number)}
                                                />
                                                <label htmlFor={`employee-${employee.employee_number}`}>{employee.full_name}</label>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="button-group">
                            <button onClick={handleAssignGoal} className="assign-button">Assign</button>
                            <button  onClick={() => setShowAssignGoalDialog(false)} className="close-button">Close</button>
                        </div>
                        {showUpdateMessage && (
                            <div className="update-message">
                                Goals assigned successfully!
                            </div>
                        )}
                    </div>
                </div>
            )}
            {renderDetailsDialog()}
            {isProfileDialogOpen && (
                <ProfileDialog
                    employeeInfo={managerInfo}
                    onClose={handleCloseProfileDialog}
                    // onUpdate={updateEmployeeInfo}
                />
            )}
        </div>
    );
};

export default MainManager;
