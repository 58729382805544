import React, { useEffect, useState, useRef } from 'react';
import './../Styles/Admin.css';
import logo from './../Images/tsqs-hm-ft-logo.gif';
import axios from 'axios';
import CreateGoal from './CreateGoal';
import AssignGoal from './AssignGoal';
import lottie from 'lottie-web';
import Lottie from 'lottie-react';
import comingSoonAnimation from './../ani/comingsoon.json';
import Notifications from './../Components/Notifications'; // Import Notifications component
import EmployeeDetailsDialog from './EmployeeDetailsDialog';
import { TailSpin } from 'react-loader-spinner'; // Using react-loader-spinner
import ProfileDialog from './ProfileDialog';
import ReviewedReports from './Reports/ReviewedReports';
const apiUrl = process.env.REACT_APP_API_URL;

const LeadDashboard = () => {
   
    const [employees, setEmployees] = useState([]);
    const [showCreateGoalDialog, setShowCreateGoalDialog] = useState(false);
    const [showAssignGoalDialog, setShowAssignGoalDialog] = useState(false);
    const [managerInfo, setManagerInfo] = useState({ full_name: '', job_title: '' });
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [employeeDetails, setEmployeeDetails] = useState([]);
    const [finalRating, setFinalRating] = useState('');
    const [submissionSuccess, setSubmissionSuccess] = useState(false); 
    const [currentSection, setCurrentSection] = useState('employees');
    const reportsContainer = useRef(null);
    const projectsContainer = useRef(null);
    const performanceContainer = useRef(null);
    const updatesContainer = useRef(null);
    const [selectedQuarter, setSelectedQuarter] = useState('Q1 2024'); 
    const [noGoalsMessage, setNoGoalsMessage] = useState('');
    const [error, setError] = useState(null);
    //for goal assigning
    const [goals, setGoals] = useState([]);
    const [selectedGoals, setSelectedGoals] = useState([]);
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [goalsDropdownOpen, setGoalsDropdownOpen] = useState(false);
    const [employeesDropdownOpen, setEmployeesDropdownOpen] = useState(false);
    const [showUpdateMessage, setShowUpdateMessage] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredEmployees, setFilteredEmployees] = useState([]);
    const [loading, setLoading] = useState(false); // Loading state
    const goalsDropdownRef = useRef(null);
    const employeesDropdownRef = useRef(null);
    //for profile
    const [isProfileDialogOpen, setProfileDialogOpen] = useState(false);
    //goal submission details
    const [reviewerRatingError, setReviewerRatingError] = useState(false); 
    const [finalRatingError, setFinalRatingError] = useState(false); 
    const [reviewerCommentError, setReviewerCommentError] = useState(false); 
    const [submissionError, setSubmissionError] = useState(null); // State for error message

    



    useEffect(() => {
        const fetchData = async () => {
            try {
                const goalsResponse = await axios.get(`${apiUrl}/api/goals`);
                console.log('Fetched goals:', goalsResponse.data);  // Debugging
                setGoals(goalsResponse.data);
                const employeesResponse = await axios.get(`${apiUrl}/api/employees`);
                console.log('Fetched employees:', employeesResponse.data);  // Debugging
                setEmployees(employeesResponse.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (goalsDropdownRef.current && !goalsDropdownRef.current.contains(event.target)) {
                setGoalsDropdownOpen(false);
            }
            if (employeesDropdownRef.current && !employeesDropdownRef.current.contains(event.target)) {
                setEmployeesDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    useEffect(() => {
        // Filter employees based on the search term
        setFilteredEmployees(employees.filter(employee =>
            employee.full_name && employee.full_name.toLowerCase().includes(searchTerm.toLowerCase())
        ));
    }, [searchTerm, employees]);


    const handleCheckboxChange = (setStateFunction, value) => {
        setStateFunction(prev => {
            if (prev.includes(value)) {
                return prev.filter(item => item !== value);
            } else {
                return [...prev, value];
            }
        });
    };


    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    useEffect(() => {
        // Load and play the Lottie animations for different sections
        lottie.loadAnimation({
            container: reportsContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: comingSoonAnimation,
        });

        lottie.loadAnimation({
            container: projectsContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: comingSoonAnimation,
        });

        lottie.loadAnimation({
            container: performanceContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: comingSoonAnimation,
        });

        lottie.loadAnimation({
            container: updatesContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: comingSoonAnimation,
        });

       
        return () => {
            lottie.destroy();
        };
    }, []);


    useEffect(() => {
        const managerId = localStorage.getItem('employeeId');
        if (!managerId) {
            console.error('Manager ID not found in local storage.');
            return;
        }
    
        // Fetch manager info
        axios.get(`${apiUrl}/api/manager-info/${managerId}`)
            .then(response => {
                setManagerInfo(response.data);
                console.log('Manager Info:', response.data);
    
                const isMainManager = response.data.is_main_manager;

    
                // Fetch lead ID based on the managerId
                return axios.get(`${apiUrl}/api/lead-id/${managerId}`);
            })
            .then(response => {
                const leadId = response.data.lead_id; // Get lead_id from response
                console.log('Lead ID:', leadId);
    
                // Fetch employees under this lead
                return axios.get(`${apiUrl}/api/employees-by-lead/${leadId}`);
            })
            .then(response => {
                setEmployees(response.data);
                console.log('Employees:', response.data);
            })
            .catch(error => setError('Error fetching data: ' + error));
    }, []);
    

    // Retrieve managerId from localStorage
    const managerId = localStorage.getItem('employeeId');
    
    const handleViewDetails = (employee, quarter) => {

         // Ensure the quarter is valid before proceeding
        if (!quarter) {
        console.error('Quarter is not defined. Cannot fetch details.');
        return; // Exit if the quarter is invalid
         }

        setEmployeeDetails([]); // Clear previous results
        setNoGoalsMessage(''); // Clear any previous message
        setSelectedEmployee(employee);
        console.log('Fetching details for employee number:', employee.employee_number); // Log the request
       
        axios.get(`${apiUrl}/api/employee-admin-goals/${employee.employee_number}/${encodeURIComponent(quarter)}`)
            .then(response => {
                // console.log('Response data:', response.data); // Log the response
                // setEmployeeDetails(response.data);
                if (response.data.length === 0) {
                    // No goals found for the selected quarter
                    console.log("details",quarter);
                    setEmployeeDetails(null);
                    setNoGoalsMessage(`No goals found for ${employee.employee_number} in ${quarter}.`);
                } else {
                    setEmployeeDetails(response.data);
                    setNoGoalsMessage(''); // Clear any previous message
                }

            })
            .catch(error => {
                console.error('Error fetching employee details:', error);
            });
    };


const handleReviewerRatingChange = (goalId, newRating) => {
    setEmployeeDetails(prevDetails =>
        prevDetails.map(detail =>
            detail.goal_id === goalId ? { ...detail, reviewer_rating: newRating } : detail
        )
    );
};

const handleSubmit = async () => {
    setLoading(true); // Start loading
    const leadId = localStorage.getItem('employeeId'); // Retrieve lead ID from local storage

    if (!leadId) {
        console.error("Lead ID (managerId) is not available in local storage.");
        return;
    }

    if (!selectedEmployee) return;
    console.log('submitting for:', selectedEmployee.employee_number);

    let hasError = false; // Local variable for error flag
      // Validate reviewer ratings for each goal
      const updatedEmployeeDetails = employeeDetails.map((detail) => {
         //Creating a copy of the details object
           const updtedDetail = { ...detail };

        if (!updtedDetail.reviewer_rating) {
            console.log('*Required');
            updtedDetail.reviewerRatingError = 'Reviewer rating is required'; // Add error to each detail
            hasError = true;
        } else {
            updtedDetail.reviewerRatingError = null; // Clear any existing error
            console.log('Valid reviewer rating for goal_id');
        }
        return updtedDetail;
    });

    // Validate reviewer comment
    if (!employeeDetails[0]?.reviewer_comment) {
        console.log('Error: Reviewer comment is required');
        setReviewerCommentError('*Reviewer Comment is required');
        hasError = true;
    } else {
        setReviewerCommentError(null);
    }

    // Stop the submission if any validation error exists
    if (hasError) {
        setEmployeeDetails(updatedEmployeeDetails);
        setLoading(false); // Stop loading
        return;
    }
    try {
        // Continue with submission if validation passes
        console.log('Validation passed. Proceeding to submit data...');
        // Submit individual goal reviews
        const goalReviewsData = {
            goals: employeeDetails.map(detail => ({
                goalId: detail.goal_id,
                reviewerRating: detail.reviewer_rating,
            })),
            employeeId: selectedEmployee.employee_number,
        };

        const goalReviewsResponse = await axios.post(
            `${apiUrl}/api/submit-goal-reviews`,
            goalReviewsData
        );
        console.log("Goal reviews response:", goalReviewsResponse);

        // Submit final rating and reviewer comment
        const finalReviewData = {
            employeeId: selectedEmployee.employee_number,
            leadId: leadId, 
            // finalRating,
            reviewerComment: employeeDetails[0]?.reviewer_comment,
            quarter: selectedQuarter // Add the selected quarter here
        };

        const finalReviewResponse = await axios.post(
            `${apiUrl}/api/notify-manager-review`,
            finalReviewData
        );

        console.log('Final review submitted:', finalReviewResponse.data);
        console.log('Goal reviews submitted:', goalReviewsResponse.data);
        // setSelectedEmployee(null);
        setLoading(false);   // Stop loading
        setSubmissionSuccess(true);
        setSubmissionError(null); // Clear any previous error message

        // Reset the selected employee and success message after a delay
        setTimeout(() => {
            // setSelectedEmployee(null);
            setSubmissionSuccess(false);
        }, 6000); // 10  seconds delay
    } catch (error) {
        console.error('Error submitting data:', error);
        setLoading(false); // Stop loading on error
        setSubmissionError('Error while submitting data. Please try again.'); // Set error message
        setTimeout(() => {
            setSubmissionError(null);
        }, 6000); // 6 seconds delay for error message
    }
};

//profile handling
const handleOpenProfileDialog = () => {
    setProfileDialogOpen(true);
};

const handleCloseProfileDialog = () => {
    setProfileDialogOpen(false);
};



    const renderDetailsDialog = () => {
        if (!selectedEmployee) return null;

        return (
            <div className="details-dialog-overlay">
                <div className="details-dialog">
                    <h2>Details of {selectedEmployee.full_name}</h2>
                    <div className="quarter-selector">
                    <label htmlFor="quarter">Select Quarter:</label>
                    <select
                        id="quarter"
                        value={selectedQuarter}
                        onChange={(e) => {
                            setSelectedQuarter(e.target.value);
                            handleViewDetails(selectedEmployee, e.target.value);
                        }}
                    >
                        <option value="Q1 2024">Q1 2024</option>
                        <option value="Q2 2024">Q2 2024</option>
                        <option value="Q3 2024">Q3 2024</option>
                        <option value="Q4 2024">Q4 2024</option>
                    </select>
                </div>

                    <div className="employee-summary">
                        <div className="summary-item">
                            <p><strong>Full Name:</strong> {selectedEmployee.full_name}</p>
                        </div>
                        <div className="summary-item">
                            <p><strong>Location:</strong> {selectedEmployee.location}</p>
                        </div>
                        <div className="summary-item">
                            <p><strong>Job Title:</strong> {selectedEmployee.job_title}</p>
                        </div>
                        <div className="summary-item">
                            <p><strong>Department:</strong> {selectedEmployee.department}</p>
                        </div>
                    </div>
                    {employeeDetails.length > 0 ? (
                        <>
                            <table className="details-table">
                                <thead>
                                    <tr>
                                        {/* <th>Goal ID</th> */}
                                        <th>Goal Description</th>
                                        <th>Self Assessment</th>
                                        <th>Self Rating</th>
                                        <th>Communicated</th>
                                        <th>Attachments</th>
                                        <th>Reviewer Rating</th>
                                        {/* <th rowSpan={employeeDetails.length + 1}>Final Rating</th>  */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {employeeDetails.map((detail, index) => (
                                        <tr key={detail.id}>
                                            {/* <td className="tabledisabled">{detail.goal_id}</td> */}
                                            <td className="tabledisabled">{detail.goal_description}</td>
                                            <td className="tabledisabled">{detail.self_assessment || 'N/A'}</td>
                                            <td className="tabledisabled">{detail.self_rating || 'N/A'}</td>
                                            <td className="tabledisabled">{detail.communicated ? 'Yes' : 'No'}</td>
                                            <td className="tabledisabled">{detail.attachments ? (
                                                    <a href={detail.attachments} target="_blank" style={{ color: '#ed9c22' }}  rel="noopener noreferrer">
                                                        View Attachment
                                                    </a>
                                                ) : 'None'}</td>
                                            <td>
                                            <select
                                                    value={detail.reviewer_rating || ''}
                                                    onChange={(e) => handleReviewerRatingChange(detail.goal_id, e.target.value)}
                                                >
                                                    <option value="" disabled>Select</option>
                                                    {[1, 2, 3, 4, 5].map(rating => (
                                                        <option key={rating} value={rating}>{rating}</option>
                                                    ))}
                                                </select>
                                                {detail.reviewerRatingError && (
                                                 <div style={{ color: 'red', fontSize: '12px' }}>
                                                   {detail.reviewerRatingError}
                                                  </div>
                                                 )}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="reviewer-comment-section">
                                <strong>Reviewer Comment:</strong>
                                <textarea 
                                    value={employeeDetails[0]?.reviewer_comment || ''} 
                                    onChange={(e) => setEmployeeDetails(prevDetails => {
                                        const updatedDetails = [...prevDetails];
                                        updatedDetails[0].reviewer_comment = e.target.value;
                                        return updatedDetails;
                                    })}
                                />
                                 {/* Display Reviewer Comment Error */}
                                    {reviewerCommentError && (
                                    <div style={{ color: 'red', fontSize: '12px' }}>
                                    {reviewerCommentError}
                                     </div>
                                  )}
                            </div>
                        </>
                    ) : (
                        <p>No goals found for this employee.</p>
                    )}
                      {submissionSuccess && (
                        <div className={`submission-success-message ${submissionSuccess ? 'visible' : ''}`}>
                            Submitted successfully!
                        </div>
                    )}
                    {submissionError && (
                      <div className={`submission-error-message visible`}>
                       {submissionError}
                      </div>
                     )}
                     {/* Display loading spinner and text */}
                     {loading && (
                         <div className="overlay-loading">
                         <div className="overlay-content-loading">
                         <TailSpin height="40" width="40" color="#00BFFF" />
                          <span className="loading-text-loading">Submitting Review...</span>
                         </div>
                         </div>
                      )}

                    <button onClick={() => setSelectedEmployee(null)}>Close</button>
                    <button onClick={handleSubmit}>Submit</button>
                </div>
            </div>
        );
    };

    return (
        <div className="admin-page">
            <aside className="sidebar">
                <div className="logo-container">
                    <img src={logo} alt="Company Logo" className="logo" />
                </div>
                <nav className="nav-menu">
                    <a href="#" className={`nav-item ${currentSection === 'employees' ? 'active' : ''}`} onClick={() => setCurrentSection('employees')}>Employees</a>
                    <a href="#" className={`nav-item ${currentSection === 'reports' ? 'active' : ''}`} onClick={() => setCurrentSection('reports')}> Reviewed Reports</a>
                    <a href="#" className={`nav-item ${currentSection === 'projects' ? 'active' : ''}`} onClick={() => setCurrentSection('projects')}>Project Reports</a>
                </nav>
            </aside>
            <main className="main-content">
                <header className="main-header">
                    <div className="profile-icon"onClick={handleOpenProfileDialog}>S</div>
                    <div className="profile-info">
                        <div className="profile-name" onClick={handleOpenProfileDialog}>{managerInfo.full_name}</div>
                        <div className="profile-role" onClick={handleOpenProfileDialog}>{managerInfo.job_title}</div>
                    </div>
                </header>
                <div className="welcome-section">
                    <h1>Hi {managerInfo.full_name}...</h1>
                </div>
                {currentSection === 'employees' && (
                <div className="employee-list-section">
                    <div className="employee-controls">
                        <div className="controls-left">
                            <h2>List of Employees</h2>
                        </div>
                        <div className="controls-right">
                            <input type="text" placeholder="Search..." className="search-bar"  onChange={handleSearchChange} />
                            <button className="sort-button">Filter</button>
                        </div>
                    </div>
                    
                  
                    <div className="employee-table-wrapper">
                        <table className="employee-table">
                            <thead>
                                <tr>
                                    <th>Employee Number</th>
                                    <th>Full Name</th>
                                    <th>Job Title</th>
                                    <th>Project</th>
                                    <th>Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                {employees.map(employee => (
                                    <tr key={employee.employee_number}>
                                        <td>{employee.employee_number || 'N/A'}</td>
                                        <td>{employee.full_name || 'N/A'}</td>
                                        <td>{employee.job_title || 'N/A'}</td>
                                        <td>{employee.project_name || 'N/A'}</td>
                                        <td>
                                            <button className="details-button" onClick={() => handleViewDetails(employee,selectedQuarter)}>Details</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                 )}
        
                {currentSection === 'reports' && (
                    <div >
                       <ReviewedReports />
                    </div>
                )
                }
                
                {currentSection === 'projects' && <div>
                    Projects COMING SOON...
                    <Lottie animationData={comingSoonAnimation} className="card-animation" />
                    </div>}

                {currentSection === 'updates' && (
                    <div className="updates-section">
                        {/* Render Notifications Component when 'Updates' is selected */}
                        <Notifications managerId={managerId} />
                    </div>
                )}
            </main>

            {showCreateGoalDialog && <CreateGoal onClose={() => setShowCreateGoalDialog(false)} />}
            {showAssignGoalDialog && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h2>Assign Goals</h2>
                        <div className="form-group">
                            <label>Select Goals:</label>
                            <div className="dropdown-container" ref={goalsDropdownRef}>
                                <button
                                    className="dropdown-button"
                                    onClick={() => setGoalsDropdownOpen(prev => !prev)}
                                >
                                    {selectedGoals.length === 0 ? 'Select Goals' : `Selected Goals (${selectedGoals.length})`}
                                </button>
                                {goalsDropdownOpen && (
                                    <div className="dropdown-menu">
                                        {goals.map(goal => (
                                            <div key={goal.id} className="checkbox-item">
                                                <input
                                                    type="checkbox"
                                                    id={`goal-${goal.id}`}
                                                    checked={selectedGoals.includes(goal.id)}
                                                    onChange={() => handleCheckboxChange(setSelectedGoals, goal.id)}
                                                />
                                                <label htmlFor={`goal-${goal.id}`}>{goal.goal_description}</label>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Select Employees:</label>
                            <div className="dropdown-container" ref={employeesDropdownRef}>
                                <button
                                    className="dropdown-button"
                                    onClick={() => setEmployeesDropdownOpen(prev => !prev)}
                                >
                                    {selectedEmployees.length === 0 ? 'Select Employees' : `Selected Employees (${selectedEmployees.length})`}
                                </button>
                                {employeesDropdownOpen && (
                                    <div className="dropdown-menu">
                                        {employees.map(employee => (
                                            <div key={employee.employee_number} className="checkbox-item">
                                                <input
                                                    type="checkbox"
                                                    id={`employee-${employee.employee_number}`}
                                                    checked={selectedEmployees.includes(employee.employee_number)}
                                                    onChange={() => handleCheckboxChange(setSelectedEmployees, employee.employee_number)}
                                                />
                                                <label htmlFor={`employee-${employee.employee_number}`}>{employee.full_name}</label>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {renderDetailsDialog()}
            {isProfileDialogOpen && (
                <ProfileDialog
                    employeeInfo={managerInfo}
                    onClose={handleCloseProfileDialog}
                    // onUpdate={updateEmployeeInfo}
                />
            )}
        </div>
    );
};

export default LeadDashboard;
