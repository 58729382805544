import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './../../Styles/AdminSectionstyles/ReviewedReports.css'

const ReviewedReports = () => {
    const [projectName, setProjectName] = useState(''); // Placeholder for project name
    const [quarter, setQuarter] = useState(''); // Placeholder for quarter
    const [employeeReports, setEmployeeReports] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');

    // Fetch employee reports data
    const fetchEmployeeReports = async () => {
        // try {
        //     const response = await axios.get('/api/reviewed-reports'); // Adjust the API endpoint as necessary
        //     setEmployeeReports(response.data);
        // } catch (error) {
        //     console.error('Error fetching employee reports:', error);
        // } finally {
        //     setLoading(false);
        // }
    };

    useEffect(() => {
        fetchEmployeeReports();
        // Set project name and quarter based on your logic or fetch them from the API
        setProjectName('Your Project Name'); // Replace with actual logic to fetch project name
        setQuarter('Q4 2024'); // Replace with actual logic to fetch quarter
    }, []);

    // Filter employee reports based on the search term
    const filteredReports = employeeReports.filter(report =>
        report.employeeName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (loading) return <div>Loading reports...</div>;

    return (
        <div className="reviewed-reports">
            <header className="dashboard-header">
                <h1>{projectName} - {quarter}</h1>
                <input
                    type="text"
                    placeholder="Search Employees..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </header>

            <div className="reports-table">
                <h2>Reviewed Employee Reports</h2>
                <table>
                    <thead>
                        <tr>
                            <th>Employee Name</th>
                            <th>Reviewer Comment</th>
                            <th>Date Reviewed</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredReports.map(report => (
                            <tr key={report.id}>
                                <td>{report.employeeName}</td>
                                <td>{report.reviewerComment || 'No Comment'}</td>
                                <td>{new Date(report.dateReviewed).toLocaleDateString()}</td>
                                <td>{report.status}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ReviewedReports;
