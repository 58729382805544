import React, { useState, useEffect } from 'react';
import axios from 'axios';
import UploadProject from './AdminSection/UploadProject';
import Lottie from 'lottie-react';
import './../Styles/MESection.css'; // Ensure to import your CSS file
import successAnimation from './../ani/celebration.json';
import AssignProject from './AdminSection/AssignProject';
const apiUrl = process.env.REACT_APP_API_URL;

const MESection = () => {
    const [subManagers, setSubManagers] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [assignments, setAssignments] = useState([]);
    const [selectedSubManagerForAssignment, setSelectedSubManagerForAssignment] = useState(null); // For assignment in section 1
    const [selectedSubManagerForDetails, setSelectedSubManagerForDetails] = useState(null); // For viewing details in section 2
    const [selectedEmployees, setSelectedEmployees] = useState([]); // Track selected employees
    const [isEmployeeDropdownVisible, setEmployeeDropdownVisible] = useState(false); // Toggle dropdown visibility
    const [filteredEmployees, setFilteredEmployees] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [dialogMessage, setDialogMessage] = useState('');
    const [isDialogVisible, setIsDialogVisible] = useState(false);

    useEffect(() => {
      // Fetch sub-managers and employees
      axios.get(`${apiUrl}/api/sub-managers`)
        .then(response => {
          setSubManagers(response.data);
        })
        .catch(error => {
          console.error('Error fetching sub-managers:', error);
        });
  
      axios.get(`${apiUrl}/api/sub-manager-employees`)
        .then(response => {
          console.log('Employees fetched:', response.data); // Log the fetched employees
          setEmployees(response.data);
        })
        .catch(error => {
          console.error('Error fetching employees:', error);
        });

    }, []);
  
    useEffect(() => {
      if (selectedSubManagerForDetails) {
        // Fetch assignments for the selected sub-manager in Section 2 (details)
        axios.get(`${apiUrl}/api/assigned-employees/${selectedSubManagerForDetails}`)
          .then(response => {
            setAssignments(response.data);
            console.log('employeeunderamanager',response.data);
          })
          .catch(error => {
            console.error('Error fetching assignments for sub-manager:', error);
          });
      }
    }, [selectedSubManagerForDetails]);
  
    // Handle assigning employees to a sub-manager
    const handleAssignEmployee = async () => {
      if (!selectedSubManagerForAssignment) {
        alert("Please select a sub-manager.");
        return;
      }
      if (selectedEmployees.length === 0) {
        alert("Please select at least one employee.");
        return;
      }
  
      try {
        await axios.post(`${apiUrl}/api/assign-employees`, {
          subManagerId: selectedSubManagerForAssignment,
          employeeIds: selectedEmployees
        });
        alert('Employees assigned successfully!');
        setSelectedEmployees([]); // Clear selected employees after assignment
      } catch (error) {
        console.error('Error assigning employees:', error);
        alert('Failed to assign employees. Please try again.');
      }
    };
  
    // Handle the checkbox change for employees
    const handleEmployeeCheckboxChange = (employeeNumber) => {
      setSelectedEmployees((prevSelected) => {
        if (prevSelected.includes(employeeNumber)) {
          return prevSelected.filter(emp => emp !== employeeNumber); // Remove from selection
        } else {
          return [...prevSelected, employeeNumber]; // Add to selection
        }
      });
    };
    

    //conversion of employee to manager
    const handleConvertEmployee = () => {
      if (!selectedEmployee) {
          alert('Please select an employee to convert.');
          return;
      }
  
      // Make the API call to convert the employee
      axios.post(`${apiUrl}/api/convert-to-sub-manager`, { employeeId: selectedEmployee })
          .then(response => {
              // Retrieve the employee name from the response
              const employeeName = response.data.name || selectedEmployee; // Fallback to ID if name is not available
              
              // Show dialog message
              setDialogMessage(`Employee ${employeeName} successfully converted to Sub-Manager (Admin).`);
              setIsDialogVisible(true);
  
              // Remove the converted employee from the list
              setFilteredEmployees(filteredEmployees.filter(emp => emp.employee_number !== selectedEmployee)); 
              setSelectedEmployee(null); // Reset selection
              setSearchTerm(''); // Reset search term
  
              // Hide dialog after 15 seconds
              setTimeout(() => {
                  setIsDialogVisible(false);
                  setDialogMessage(''); // Clear the message after hiding
              }, 15000);
          })
          .catch(error => {
              console.error('Error converting employee:', error);
              alert('Failed to convert employee.');
          });
  };
  
    // Handle employee search
    const handleSearch = (event) => {
      const value = event.target.value;
      setSearchTerm(value);
      
      setFilteredEmployees(
          employees.filter(employee => {
              // Check if employee.full_name is not null or undefined
              if (employee.full_name) {
                  return employee.full_name.toLowerCase().includes(value.toLowerCase());
              }
              return false; // Exclude employees without a full_name
          })
      );
  };
  
  
    return (
      <div className="me-section-content">
        <div className="flex-container">
          {/* Section 1: Assign Employees to Sub-Managers */}
          {/* <div className="section-row">
          <div className="employee-assignment-section">
            <h2>Assign Employees to Manager</h2>
            <div className="form-group">
              <label>Select Manager (for assignment):</label>
              <select
                onChange={(e) => setSelectedSubManagerForAssignment(e.target.value)}
                className="dropdown"
                value={selectedSubManagerForAssignment || ""}
                >
                <option value="">Select Manager</option>
                {subManagers.map(subManager => (
                  <option key={subManager.employee_number} value={subManager.employee_number}>
                    {subManager.full_name}
                  </option>
                ))}
              </select>
            </div>
  
            <div className="form-group">
              <label>Select Employees:</label>
              <div
                className="dropdown-header"
                onClick={() => setEmployeeDropdownVisible(!isEmployeeDropdownVisible)}
              >
                {selectedEmployees.length === 0
                  ? "Select Employees"
                  : `Selected Employees (${selectedEmployees.length})`}
              </div>
  
              {isEmployeeDropdownVisible && (
                <div className="dropdown-container">
                  {employees.map(employee => (
                    <div key={employee.employee_number} className="checkbox-item">
                      <input
                        type="checkbox"
                        id={`employee-${employee.employee_number}`}
                        checked={selectedEmployees.includes(employee.employee_number)}
                        onChange={() => handleEmployeeCheckboxChange(employee.employee_number)}
                      />
                      <label htmlFor={`employee-${employee.employee_number}`}>{employee.full_name}</label>
                    </div>
                  ))}
                </div>
              )}
            </div>
  
            <button onClick={handleAssignEmployee} className="assign-button">
              Assign Employees
            </button>
          </div>
          </div> */}

          {/* Section 2: Sub-Manager Details */}
          <div className="section-row">
          <div className="sub-manager-details">
            <h2>Assigned employee to Manager</h2>
            <div className="form-group">
              <label>Select Manager (for details):</label>
              <select
                onChange={(e) => setSelectedSubManagerForDetails(e.target.value)}
                className="dropdown"
              >
                <option value="">Select Manager</option>
                {subManagers.map(subManager => (
                  <option key={subManager.employee_number} value={subManager.employee_number}>
                    {subManager.full_name}
                  </option>
                ))}
              </select>
            </div>
            {selectedSubManagerForDetails && (
              <div className="employee-list">
                <h3>Employees under Manager</h3>
                <ul>
                  {/* {assignments.filter(assignment => assignment.manager_id === selectedSubManagerForDetails).map(assignment => (
                    <li key={assignment.employee_number}>
                      {assignment.full_name}
                    </li>
                  ))} */}
                  {assignments.map(assignment => (
                  <li key={assignment.employee_number}>
                  {assignment.full_name}
                  </li>
                   ))}
                </ul>
              </div>
            )}
          </div>
          </div>

          {/* Section 3: Convert Employee to Sub-Manager */}
          <div className="convert-to-sub-manager">
                    <h2>Convert Employee to Manager</h2>
                    <div className="search-container">
                        <input
                            type="text"
                            placeholder="Search Employee by Name"
                            value={searchTerm}
                            onChange={handleSearch}
                            className="search-input"
                        />
                    </div>

                    <div className="employee-list">
                        {filteredEmployees.length === 0 ? (
                            <p>No employees found</p>
                        ) : (
                            filteredEmployees.map(employee => (
                                <div key={employee.employee_number} className="employee-row">
                                    <input
                                        type="checkbox"
                                        id={`employee-${employee.employee_number}`}
                                        name="employeeSelect"
                                        value={employee.employee_number}
                                        onChange={() => setSelectedEmployee(employee.employee_number)}
                                    />
                                    <label className="local-label" htmlFor={`employee-${employee.employee_number}`}>
                                        {employee.full_name || "Name not available"}
                                    </label>
                                </div>
                            ))
                        )}
                    </div>

                    <button onClick={handleConvertEmployee} className="convert-button">
                        Convert to Sub-Manager
                    </button>
                  {isDialogVisible && (
                    <div className="dialog-conversion">
                    <Lottie animationData={successAnimation} loop={true} className="dialog-conversion-animation" />
                    <p className="dialog-conversion-message">{dialogMessage}</p>
                    <button className="conversion-close-button" onClick={() => setIsDialogVisible(false)}>Close</button>
                    </div>
                  )}
          </div>

          {/*Section 4: Uploading Projects to Database */}
          <div className="section-row">
            <div className="uploading-projects">
             <UploadProject />
             </div>
          </div>
          <div className="section-row">
            <div className="uploading-projects">
             <AssignProject/>
             </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default MESection;