import React, { useState } from 'react';
import ConvertToLead from './LeadManagement/ConvertToLead'; 
import ViewLeads from './LeadManagement/ViewLeads';
import './../Styles/LeadManagement.css';
import AssignLeads from './LeadManagement/AssignLeads';

const LeadManagement = ({ managerId }) => {
  const [currentSection, setCurrentSection] = useState('convertToLead'); // Default to the third section

  return (
    <div className="lead-management-container">
      {/* Navigation to switch between sections */}
      <div className="lead-management-nav">
        <button onClick={() => setCurrentSection('viewLeads')} className={currentSection === 'viewLeads' ? 'active' : ''}>View Leads</button>
        <button onClick={() => setCurrentSection('assignLead')} className={currentSection === 'assignLead' ? 'active' : ''}>Assign Lead to Project</button>
        <button onClick={() => setCurrentSection('convertToLead')} className={currentSection === 'convertToLead' ? 'active' : ''}>Convert Employee to Lead</button>
      </div>

      {/* Horizontal Sections */}
      <div className="horizontal-sections">
        {/* First section: View Leads */}
        {currentSection === 'viewLeads' && (
          <div className="section">
            <ViewLeads managerId={managerId} />
            {/* Content for viewing leads */}
          </div>
        )}

        {/* Second section: Assign Lead to Project */}
        {currentSection === 'assignLead' && (
          <div className="section">
            <AssignLeads managerId={managerId} />
          </div>
        )}

        {/* Third section: Convert Employee to Lead */}
        {currentSection === 'convertToLead' && (
          <div className="section">
            <ConvertToLead managerId={managerId} /> {/* Convert to lead form */}
          </div>
        )}
      </div>
    </div>
  );
};

export default LeadManagement;
