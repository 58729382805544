import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './../Styles/Notifications.css';

const Notifications = ({ managerId }) => {
    const [notifications, setNotifications] = useState([]);
    const [unreadCount, setUnreadCount] = useState(0); // State for unread notifications
    const [showNotifications, setShowNotifications] = useState(false); // Toggle for showing notification list

    // Fetch unread notification count
    useEffect(() => {
        const fetchUnreadCount = async () => {
            try {
                const response = await axios.get(`/api/notifications/unread/${managerId}`);
                setUnreadCount(response.data.unreadCount || 0);
            } catch (error) {
                console.error('Error fetching unread notification count:', error);
            }
        };

        fetchUnreadCount();

        const interval = setInterval(fetchUnreadCount, 10000); // Poll every 10 seconds for unread count
        return () => clearInterval(interval); // Cleanup on component unmount
    }, [managerId]);

    // Fetch notifications list when clicked
    const fetchNotifications = async () => {
        try {
            const response = await axios.get(`/api/notifications/${managerId}`);
            setNotifications(response.data);
            setShowNotifications(true); // Show notifications list
        } catch (error) {
            console.error('Error fetching notifications:', error);
        }
    };

    return (
        <div className="notifications-section">
            <div className="updates-header" onClick={fetchNotifications}>
                Updates
                {unreadCount > 0 && <span className="notification-badge">{unreadCount}</span>}
            </div>

            {showNotifications && (
                <div className="notifications-list">
                    <h3>Notifications</h3>
                    <ul>
                        {notifications.map((notification, index) => (
                            <li key={index}>
                                {notification.message} - {new Date(notification.timestamp).toLocaleString()}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default Notifications;
