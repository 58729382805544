import React, { useState } from 'react';
import axios from 'axios';
import './../Styles/CreateGoal.css';  // Adjust the path according to your folder structure
const apiUrl = process.env.REACT_APP_API_URL;

const CreateGoal = ({ onClose }) => {
    const [goal, setGoal] = useState('');

    const handleCreateGoal = async () => {
        if (!goal.trim()) { // Check if goal is an empty string or contains only whitespace
            alert('Goal description cannot be empty');
            return;
        }
        try {
            const response = await axios.post(`${apiUrl}/api/create-goal`, { goal_description: goal });
            alert('Goal created successfully');
            console.log('Goal creation response:', response);
            onClose();
        } catch (error) {
            console.error('Error creating goal:', error);
            alert('Error creating goal');
        }
    };
    
    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Create Goal</h2>
                <textarea
                    value={goal}
                    onChange={(e) => setGoal(e.target.value)}
                    placeholder="Enter goal details"
                />
                
                <button className="creatgoalbtn" onClick={handleCreateGoal}>Create</button>
                <button className="creatgoalbtn" onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default CreateGoal;
