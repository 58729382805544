import React, { useState } from 'react';
import './../../Styles/AdminSectionstyles/UploadProject.css';

const apiUrl = process.env.REACT_APP_API_URL;

function UploadProject() {
  const [projectName, setProjectName] = useState('');
  const [description, setDescription] = useState('');
  const [statusMessage, setStatusMessage] = useState('');

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Debugging log
    console.log('Submitting:', { project_name: projectName, description });
  
    const projectData = {
      project_name: projectName,
      description,
    };
  
    try {
      const response = await fetch(`${apiUrl}/api/upload-project`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(projectData),
      });
  
      if (response.ok) {
        setProjectName('');
        setDescription('');
        setStatusMessage('Project uploaded successfully!');

        setTimeout(() => {
          setStatusMessage('');
        }, 5000);

      } else {
        setStatusMessage('Failed to upload the project. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      setStatusMessage('An error occurred. Please try again.');
    }
  };
  

  return (
    <div className="upload-project">
      <h2 style={{ textAlign: 'left' }}>Upload a New Project</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Project Name:</label>
          <input
            type="text"
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
            required
            style={{ width: '100%' }} // Ensure full width
          />
        </div>
        <div className="form-group">
          <label>Description:</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
            rows="3" // Adjust the number of rows to decrease height
            style={{ width: '100%' }} // Ensure full width
          />
        </div>
        <button type="submit">Upload Project</button>
      </form>
      {statusMessage && <p className="upload-status-message">{statusMessage}</p>}
    </div>
  );
}

export default UploadProject;
