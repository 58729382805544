import React from 'react';
import './../Styles/LoadingOverlay.css'; // Import CSS for styling

const LoadingOverlay = () => (
  <div className="loading-overlay">
    <div className="spinner"></div>
    <p>Submitting...</p>
  </div>
);

export default LoadingOverlay;
