import React, { useState, useEffect } from 'react';
import axios from 'axios'; 
import './../Styles/Knowledge.css'
const apiUrl = process.env.REACT_APP_API_URL;


// const AcknowledgmentButton = ({ employeeId, goalId, reviewerComment, finalRating, acknowledged, quarter, onAcknowledged }) => {
//     const [isAcknowledged, setIsAcknowledged] = useState(acknowledged);
//     const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    

//     // Enable button only if reviewerComment and finalRating are present
//     useEffect(() => {
//         if (reviewerComment && finalRating !== null) {
//             setIsButtonEnabled(true);
//         } else {
//             setIsButtonEnabled(false);
//         }
//     }, [reviewerComment, finalRating]);

//     // Handle Acknowledgment
//     const handleAcknowledge = async () => {
//         if (!isButtonEnabled || isAcknowledged) return; // Prevent action if button is disabled or already acknowledged

//         try {
//             // Send acknowledgment request to the backend, including the quarter
//             await axios.post(`${apiUrl}/api/acknowledge`, { employeeId, quarter });
            
//             // Update local state to reflect acknowledgment
//             setIsAcknowledged(true);
            
//             // Notify parent component to update its state
//             onAcknowledged(goalId);
//         } catch (error) {
//             console.error('Error acknowledging goal:', error);
//             alert('Failed to send acknowledgment');
//         }
//     };

//     return (
//         <button
//             className="acknowledgment-button"
//             onClick={handleAcknowledge}
//             disabled={!isButtonEnabled || isAcknowledged}
//         >
//             {isAcknowledged ? 'Acknowledged' : 'Acknowledge'}
//         </button>
//     );
// };

// export default AcknowledgmentButton;


const AcknowledgmentButton = ({ employeeId, goalId, reviewerComment, finalRating, acknowledged, quarter, onAcknowledged }) => {
    const [isAcknowledged, setIsAcknowledged] = useState(acknowledged);
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const [isMessageVisible, setIsMessageVisible] = useState(false);
    const [message, setMessage] = useState('');
    

    // Enable button only if reviewerComment and finalRating are present
    useEffect(() => {
        if (reviewerComment && finalRating !== null) {
            setIsButtonEnabled(true);
        } else {
            setIsButtonEnabled(false);
        }
    }, [reviewerComment, finalRating]);

    // Check the initial acknowledgment state
    useEffect(() => {
        setIsAcknowledged(acknowledged);
    }, [acknowledged]); // Update state if the acknowledged prop changes

    // Handle Acknowledgment
    const handleAcknowledge = async () => {
        if (!isButtonEnabled || isAcknowledged) return; // Prevent action if button is disabled or already acknowledged

        try {
            // Send acknowledgment request to the backend, including the quarter
            await axios.post(`${apiUrl}/api/acknowledge`, { employeeId, quarter });

            // Update local state to reflect acknowledgment
            setIsAcknowledged(true);
            
            // Show success message
            setMessage(`You have successfully acknowledged the review for ${quarter}.`);
            setIsMessageVisible(true);
            // Hide message after 3 seconds
            setTimeout(() => {
                setIsMessageVisible(false);
            }, 5000);
            // Notify parent component to update its state
            onAcknowledged(goalId);
        } catch (error) {
            console.error('Error acknowledging goal:', error);
            alert('Failed to send acknowledgment');
        }
    };

    // Close message
    const handleCloseMessage = () => {
        setIsMessageVisible(false);
    };

    return (
        <div className="acknowledgment-container">
               {/* Success/Error Message */}
               {isMessageVisible && (
                <div className="success-message">
                    {message}
                    {/* <button onClick={handleCloseMessage}>Close</button> */}
                </div>
            )}
            <button
                className="acknowledgment-button"
                onClick={handleAcknowledge}
                disabled={!isButtonEnabled || isAcknowledged} // Disable if not enabled or already acknowledged
            >
                {isAcknowledged ? 'Acknowledged' : 'Acknowledge'}
            </button>

         
        </div>
        
    );
};

export default AcknowledgmentButton;
