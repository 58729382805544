import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './../../Styles/leadstyles/AssignLeads.css'
// import './../../Styles/leadstyles/ViewLeads.css'; // Ensure the path is correct

const apiUrl = process.env.REACT_APP_API_URL;

const AssignLeads = ({ managerId }) => {
  const [leads, setLeads] = useState([]); // State to store leads
  const [projects, setProjects] = useState([]); // State to store projects
  const [selectedLead, setSelectedLead] = useState(''); // State for selected lead
  const [selectedProject, setSelectedProject] = useState(''); // State for selected project
  const [message, setMessage] = useState(''); // State for success message
  const [errorMessage, setErrorMessage] = useState(''); // State for error message

  // Fetch leads and projects when component mounts or managerId changes
  useEffect(() => {
    fetchLeads();
    fetchProjects();
  }, [managerId]);

  // Function to fetch leads
  const fetchLeads = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/view-leads`, {
        params: { managerId },
      });
      setLeads(response.data);
    } catch (error) {
      console.error('Error fetching leads:', error.response ? error.response.data : error.message);
      setErrorMessage('Failed to load leads. Please try again.');
    }
  };

  // Function to fetch projects
  const fetchProjects = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/get-projects/${managerId}`);
      setProjects(response.data);
    } catch (error) {
      console.error('Error fetching projects:', error.response ? error.response.data : error.message);
      setErrorMessage('Failed to load projects. Please try again.');
    }
  };

  // Function to fetch lead ID based on employee number
  const getLeadId = async (employeeNumber) => {
    try {
      const response = await axios.get(`${apiUrl}/api/get-lead-id/${employeeNumber}`);
      return response.data.lead_id; // Return the lead ID
    } catch (error) {
      console.error('Error fetching lead ID:', error.response ? error.response.data : error.message);
      setErrorMessage('Failed to fetch lead ID. Please try again.');
      return null; // Return null if there's an error
    }
  };

  // Function to check if a lead is already assigned to a project
  const checkLeadAssignment = async (leadId, projectId) => {
    try {
      const response = await axios.get(`${apiUrl}/api/check-lead-assignment`, {
        params: { lead_id: leadId, project_id: projectId },
      });
      return response.data.assigned; // Assuming the response returns { assigned: true/false }
    } catch (error) {
      console.error('Error checking lead assignment:', error.response ? error.response.data : error.message);
      setErrorMessage('Error checking assignment status. Please try again.');
      return false; // Assume not assigned if there's an error
    }
  };

  // Function to handle lead assignment
  const handleAssignLead = async () => {
    setMessage('');
    setErrorMessage('');

    if (!selectedLead || !selectedProject) {
      setErrorMessage('Please select both a lead and a project');
      return;
    }

    console.log('Selected Lead ID:', selectedLead);
    console.log('Selected Project ID:', selectedProject);

    // Fetch lead_id using the selected lead's employee_number
    const leadId = await getLeadId(selectedLead);
    
    if (!leadId) {
      // If leadId is null, it means there was an error fetching it
      return; 
    }

    // Check if the lead is already assigned to the selected project
    const isAssigned = await checkLeadAssignment(leadId, parseInt(selectedProject));
    
    if (isAssigned) {
      setErrorMessage('Lead is already assigned to this project.');
      // Set a timeout to clear the message after 3 minutes
      setTimeout(() => setErrorMessage(''), 180000);
      return;
    }

    try {
      const response = await axios.post(`${apiUrl}/api/assign-lead`, {
        lead_id: leadId, // Use the fetched lead ID
        project_id: parseInt(selectedProject), // Convert to integer
      });

      // Handle response based on the success or error
      if (response.data.success) {
        setMessage('Lead assigned to project successfully!');
      } else {
        setErrorMessage('Unexpected error occurred. Please try again.');
      }

      // Reset selected values
      setSelectedLead('');
      setSelectedProject('');
      fetchLeads(); // Refresh leads
    } catch (error) {
      console.error('Error assigning lead to project:', error.response ? error.response.data : error.message);
      setErrorMessage('Error assigning lead to project. Please try again.');
    }
  };

  return (
    <div className="assign-leads">
      <h3>Assign Leads to Projects</h3>
      {message && <div className="success-message">{message}</div>}
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      
      <div>
        <label htmlFor="lead-select">Select Lead:</label>
        <select
          id="lead-select"
          value={selectedLead}
          onChange={(e) => setSelectedLead(e.target.value)} // This should be the ID
        >
          <option value="">Select a lead</option>
          {leads.map((lead) => (
            <option key={lead.id} value={lead.employee_number}> {/* Ensure this is the employee number */}
              {lead.full_name}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label htmlFor="project-select">Select Project:</label>
        <select
          id="project-select"
          value={selectedProject}
          onChange={(e) => setSelectedProject(e.target.value)} // This should be the project ID
        >
          <option value="">Select a project</option>
          {projects.map((project) => (
            <option key={project.id} value={project.id}> {/* Ensure this is the project ID */}
              {project.project_name}
            </option>
          ))}
        </select>
      </div>

      <button onClick={handleAssignLead}>Assign Lead</button>
    </div>
  );
};

export default AssignLeads;
