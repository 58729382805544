import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import logo from './../Images/tsqs-hm-ft-logo.gif';
import picon from './../Images/profile_icon.png';
import './../Styles/Employee.css';
import { FaInfoCircle } from 'react-icons/fa'; 
import { faHistory } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import ProfileDialog from './ProfileDialog'; // Import the ProfileDialog component
import LoadingOverlay from './LoadingOverlay'; // Import the LoadingOverlay component
import NotificationModal from './NotificationModal'; // Adjust the path accordingly
import TextModal from './TextModal'; // Adjust path as needed
const apiUrl = process.env.REACT_APP_API_URL;

const Employee = () => {
    const [goalDescription, setGoalDescription] = useState('');
    const [goals, setGoals] = useState([]);
    const [employeeInfo, setEmployeeInfo] = useState({ fullName: '', jobTitle: '', employee_number: '' });
    const [reviewerComment, setReviewerComment] = useState('');
    const [selectedGoal, setSelectedGoal] = useState(null);
    console.log('selectedId',selectedGoal);
    const [goalDetails, setGoalDetails] = useState('');
    console.log('goaldetails',goalDetails)
    const [link, setLink] = useState('');
    console.log('linvalue',link);
    const [selfRatings, setSelfRatings] = useState({});
    const [communicatedStatuses, setCommunicatedStatuses] = useState({});
    const [isInstructionsOpen, setInstructionsOpen] = useState(false);
    const [quarters, setQuarters] = useState([]);
    const [selectedQuarter, setSelectedQuarter] = useState('');
    const [finalRating, setFinalRating] = useState('');
    const [goalDescriptions, setGoalDescriptions] = useState({});
    const [attachmentLinks, setAttachmentLinks] = useState({});
    const [isProfileDialogOpen, setProfileDialogOpen] = useState(false); // State for profile dialog
    const [errors, setErrors] = useState({}); //error identifications
    const [showMessage, setShowMessage] = useState(false); //quarter not selected message
    const [successMessage, setSuccessMessage] = useState('');
    const [loadingGoalId, setLoadingGoalId] = useState(null); // Track which goal is being processed
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [currentField, setCurrentField] = useState(null);
    const [currentGoalId, setCurrentGoalId] = useState(null);
    const [text, setText] = useState('');
    const [initialText, setInitialText] = useState('');

   

    //for textmodal
    const openModal = (field, goalId, existingText) => {
        setCurrentField(field);
        setCurrentGoalId(goalId);
        if (field === 'description') {
            setInitialText(goalDescriptions[goalId] || '');
        } else if (field === 'attachment') {
            setInitialText(attachmentLinks[goalId] || '');
        }
        setModalIsOpen(true);
        
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setInitialText(''); // Reset text on close to ensure freshness next time
    };

    const handleTextSubmit = (submittedText) => {
        if (currentField === 'description' && currentGoalId !== null) {
            handleDescriptionChange(currentGoalId, submittedText);
        } else if (currentField === 'attachment' && currentGoalId !== null) {
            handleAttachmentChange(currentGoalId, submittedText);
        }
        closeModal();
    };
  //end of textmodal

    const navigate = useNavigate();  
   
    useEffect(() => {
        const fetchEmployeeData = async () => {
           
            const employeeId = localStorage.getItem('employeeId');
            console.log('Retrieved Employee ID:', employeeId);
            console.log('Stored Employee ID:', localStorage.getItem('employeeId'));

            if (!employeeId) {
                console.error('Employee ID not found in local storage.');
                return;
            }
            try {
                const [employeeResponse, goalsResponse,commentResponse] = await Promise.all([
                    axios.get(`${apiUrl}/api/employee-info/${employeeId}`),
                    axios.get(`${apiUrl}/api/employee-goals/${employeeId}`),
                    // axios.get(`http://localhost:3001/api/reviewer-comment/${employeeId}`)
                ]);
                console.log('Fetched Employee Info:', employeeResponse.data); // Log the response data
                setEmployeeInfo(employeeResponse.data);
                console.log('testing',employeeResponse.data);
                // const goalsWithRatings = await Promise.all(goalsResponse.data.map(async (goal) => {
                //     const reviewerRating = await fetchReviewerRating(employeeResponse.data.employee_number, goal.id);
                //     return { ...goal, reviewer_rating: reviewerRating };
                // }));
                // Filter out duplicate goals based on goal_id
                const uniqueGoals = Array.from(new Map(goalsResponse.data.map(goal => [goal.id, goal])).values());

                // Fetch reviewer ratings for each unique goal
                const goalsWithRatings = await Promise.all(uniqueGoals.map(async (goal) => {
                    const reviewerRating = await fetchReviewerRating(employeeResponse.data.employee_number, goal.id);
                    return { ...goal, reviewer_rating: reviewerRating };
                }));
                setGoals(goalsWithRatings);
                // setReviewerComment(commentResponse.data.reviewer_comment || 'No Comment1');
                
                if (selectedQuarter) {
                    await fetchReviewerCommentForQuarter(selectedQuarter);
                    await fetchFinalRatingForQuarter(selectedQuarter);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    
        fetchEmployeeData();
    }, []);

     //fetching quarters api
     useEffect(() => {
        const fetchQuarters = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/quarters`);
                setQuarters(response.data);
            } catch (error) {
                console.error('Error fetching quarters:', error);
            }
        };
    
        fetchQuarters();
    }, []);

    useEffect(() => {
        if (selectedQuarter) {
            fetchReviewerCommentForQuarter(selectedQuarter);
            fetchFinalRatingForQuarter(selectedQuarter);
        }
    }, [selectedQuarter]);

    const fetchReviewerCommentForQuarter = async (quarter) => {
        
        try {
            const response = await axios.get(`${apiUrl}/api/reviewer-comment/${employeeInfo.employee_number}/${quarter}`);
            setReviewerComment(response.data.reviewer_comment || 'No Comment');
            console.log('reviwecoment',response.data.reviewer_comment)
        } catch (error) {
            console.error('Error fetching reviewer comment:', error);
        }
    };

    const fetchFinalRatingForQuarter = async (quarter) => {
        try {
            const response = await axios.get(`${apiUrl}/api/reviewer-comment/${employeeInfo.employee_number}/${quarter}`);
            setFinalRating(response.data.final_rating || 'N/A');
        } catch (error) {
            console.error('Error fetching final rating:', error);
        }
    };
    




    useEffect(() => {
        console.log('Employee Info State:', employeeInfo); // Verify this includes employee_number
    }, [employeeInfo]);
    

    const fetchReviewerRating = async (employeeId, goalId) => {
      
        try {
            const response = await axios.get(`${apiUrl}/api/goal-review/${employeeId}/${goalId}`);
            console.log('reviewer',employeeId,goalId);
            return response.data.reviewer_rating || 'N/A'; // Return 'N/A' if no rating is found
        } catch (error) {
            console.error('Error fetching reviewer rating:', error);
            return 'N/A';
        }
    };

    const handleSubmitRow = async (goalId) => {

        const employeeId = employeeInfo.employee_number;
        console.log(employeeId);

         // Check if the quarter is selected
         if (!selectedQuarter) {
            setShowMessage(true); // Show the message if no quarter is selected
        } else {
            // Perform your form submission logic here...
            console.log("Quarter selected:", selectedQuarter);
            setShowMessage(false); // Hide message after submission or valid action
        }

    // Collect data for validation
    const description = goalDescriptions[goalId] || '';
    const rating = selfRatings[goalId] || '';
    const communicated = communicatedStatuses[goalId] || '';

    // Validate fields
    let newErrors = {};
    if (!description) newErrors[goalId] = 'This field is required';
    if (!rating) newErrors[goalId] = 'This field is required';
    if (!communicated) newErrors[goalId] = 'This field is required';

    // If there are errors, set the error state and return
    if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        return;
    }

    // Clear errors if validation passes
    setErrors({});
        if (selectedQuarter && employeeId) {
            setLoadingGoalId(goalId);
            try {
                await axios.post(`${apiUrl}/api/update-goal-row`, {
                    goalId,
                    self_assessment: goalDescriptions[goalId] || '', // Text description
                    attachments: attachmentLinks[goalId] || '',      // Attachment link
                    self_rating: selfRatings[goalId] || '',
                    communicated: communicatedStatuses[goalId] || '',
                    employeeId,
                    quarter: selectedQuarter,
                    is_submitted: true // Mark the goal as submitted
                });
                setSuccessMessage('Goal submitted successfully.');
                setTimeout(() => setSuccessMessage(''), 7000); // Clear message after 10 seconds
                console.log('Row submitted successfully');
            } catch (error) {
                if (error.response && error.response.status === 400) {
                    setModalMessage('Goal has already been submitted for this quarter.');
                    setShowModal(true);
                  } else {
                    console.error('Error updating goal:', error);
                  }
                // console.error('Error updating goal from row:', error);
            }
            finally {
                setLoadingGoalId(null); // Reset loading state
              }
        } else {
            console.error('Quarter or employee ID not selected');
        }
    };
    

    const handleDescriptionChange = (goalId, description) => {
        setGoalDescriptions(prev => ({ ...prev, [goalId]: description }));
    };

    const handleAttachmentChange = (goalId, link) => {
        setAttachmentLinks(prev => ({ ...prev, [goalId]: link }));
    };



      const handleOpenInstructions = () => {
        setInstructionsOpen(true);
    };



    const handleCloseInstructions = () => {
        setInstructionsOpen(false);
    };

    const handleHistoryClick = () => {
        navigate('/history');  // Navigate to the History page
    };
     

    const handleOpenProfileDialog = () => {
        setProfileDialogOpen(true);
    };

    const handleCloseProfileDialog = () => {
        setProfileDialogOpen(false);
    };
     
    useEffect(() => {
        if (showMessage) {
            // Set a timer to hide the message after 5 seconds
            const timer = setTimeout(() => {
                setShowMessage(false);
            }, 10000);

            // Cleanup the timer if the component unmounts
            return () => clearTimeout(timer);
        }
    }, [showMessage]);
    
    
    

    return (
        <div className="employee-page">
            <header className="employee-header">
                <div className="company-logo">
                    <img src={logo} alt="Company Logo" className="logo" />
                </div>
                <h1 className="header-title">PERFORMANCE MANAGEMENT</h1>
                <div className="employee-info">
                    <img src={picon} alt="Profile Icon" className="profileicon" onClick={handleOpenProfileDialog}/>
                    <div className="employee-details">
                    <span className="employee-name" onClick={handleOpenProfileDialog}>{employeeInfo.full_name}</span>
                    <span className="employee-job" onClick={handleOpenProfileDialog}>{employeeInfo.job_title}</span>
                    </div>
                </div>
            </header>
            <main className="employee-body">

            {showModal && (
               <NotificationModal
               message={modalMessage}
               onClose={() => setShowModal(false)}
               />
             )}
            {loadingGoalId && <LoadingOverlay />} {/* Show overlay when loadingGoalId is set */}
            <div className="goals-header-container">
                <h2>Assigned Goals</h2>
               
            <div className="quarter-history-container">
               <div className="quarter-selector">
                    <label htmlFor="quarter">Select Quarter:</label>
                   <select    
                             id="quarter"
                             value={selectedQuarter}
                             onChange={(e) => setSelectedQuarter(e.target.value)}
                     >
                    <option value="">Select Quarter</option>
                     {quarters.map((quarter) => (
                     <option key={quarter.id} value={quarter.quarter}>
                      {quarter.quarter}
                     </option>
                        ))}
                 </select>
              </div>
              
              <button className="history-btn" onClick={handleHistoryClick}>
                    <FontAwesomeIcon icon={faHistory} /> Submitted
                </button>
                <button className="instructions-btn" onClick={handleOpenInstructions}>
                        <FaInfoCircle size={24} />
                    </button>
            </div>
            </div>
                <table className="goals-table">
                    <thead>
                        <tr>
                            {/* <th>Goal ID</th> */}
                            <th>Goal Description</th>
                            <th>Description Text</th>
                            <th>Attachment</th>
                            <th>Self Rating</th>
                            <th>Communicated</th>
                            <th>Submit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {goals.map((goal) => (
                            <tr key={goal.id}>
                                {/* <td>{goal.id}</td> */}
                                <td>{goal.goal_description}</td>
                                <td>
                                <input 
                                        type="text"
                                        value={goalDescriptions[goal.id] || ''}
                                        onChange={(e) => handleDescriptionChange(goal.id, e.target.value)}
                                        onClick={() => openModal('description', goal.id, goalDescriptions[goal.id] || '')}
                                    />
                                     {errors[goal.id] && <span className="error-message">{errors[goal.id]}</span>}
                                </td> 
                                <td>
                                <input 
                                        type="text"
                                        value={attachmentLinks[goal.id] || ''}
                                        onChange={(e) => handleAttachmentChange(goal.id, e.target.value)}
                                        onClick={() => openModal('attachment', goal.id, attachmentLinks[goal.id] || '')}
                                    />
                                </td>
                                <td>
                                    <select
                                        value={selfRatings[goal.id] || ''}
                                        onChange={(e) => setSelfRatings(prev => ({ ...prev, [goal.id]: e.target.value }))}
                                    >
                                        <option value="">Select</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                    </select>
                                    {errors[goal.id] && <span className="error-message">{errors[goal.id]}</span>}
                                </td>
                                <td>
                                    <select
                                        value={communicatedStatuses[goal.id] || ''}
                                        onChange={(e) => setCommunicatedStatuses(prev => ({ ...prev, [goal.id]: e.target.value }))}
                                    >
                                        <option value="">Select</option>
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                    </select>
                                    {errors[goal.id] && <span className="error-message">{errors[goal.id]}</span>}
                                </td>
                                <td>
                                    <button onClick={() => handleSubmitRow(goal.id)} disabled={loadingGoalId === goal.id}>
                                    {loadingGoalId === goal.id ? 'Submitting...' : 'Submit'}
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                    <TextModal
                        isOpen={modalIsOpen}
                         onRequestClose={closeModal}
                        onTextSubmit={handleTextSubmit}
                        initialText={initialText}
                        field={currentField}
                     />
                {/* <div className="reviewer-comment-box">
                    <h3>Reviewer Comment:</h3>
                    <p>{reviewerComment}</p>
                </div> */}
                <div className={`instructions-modal ${isInstructionsOpen ? 'open' : ''}`}>
                    <h3>Goal Submission and Review Process</h3>
                <ol>
        <li>Goals will be dynamically fetched in your dashboard once your manager assigns goals to you.</li>
        <li>You need to select the particular quarter from the dropdown for which you are submitting the goal.</li>
        
        <li>When entering your goal description, please write it first in a notepad application and paste it into the input box. Note that the save option is not yet available.</li>
        
        <li>To upload certifications or attachments for a goal, please store the files in your OneDrive and paste the shared link into the “Attachments” field for the goal.</li>
        
        <li>After submitting a goal, your lead and manager will be notified automatically.</li>
        
        <li>You can view all submitted goals for the current quarter in the “Submitted” section.</li>
        
        <li>Once your manager has reviewed your goals, you will receive an email notification. You can then view the reviewer’s rating, comments, and final rating for the quarter in the “Submitted” section.</li>
        
        <li>After the manager’s review is completed, the “Archive” button will become available for particular quarter. If you are satisfied with the Comment and Final Rating, click “Archive” to confirm and accept your review for the quarter. An email will be sent to your manager confirming that you have accepted the review.</li>
        
        <li>If you encounter any issues, please reach out to us at <a href="mailto:pms@tsqsinc.com">pms@tsqsinc.com</a> for assistance.</li>
    </ol>

                    <button className="close-instructions" onClick={handleCloseInstructions}>Close</button>
                    
                </div>
            </main>
            {isProfileDialogOpen && (
                <ProfileDialog
                    employeeInfo={employeeInfo}
                    onClose={handleCloseProfileDialog}
                    // onUpdate={updateEmployeeInfo}
                />
            )}
                {showMessage && (
                <div className="status-message">
                    <p>Please select a quarter before submitting</p>
                    <i className="fas fa-times close-icon" onClick={() => setShowMessage(false)}></i>
                </div>
            )}
               {successMessage && (
                   <div className="status-message">
                        <p>{successMessage}</p>
                    </div>
             )}
              
        </div>
    );
};

export default Employee;
