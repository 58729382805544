import './../Styles/TextModal.css'; // Import the CSS file
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // Ensure this matches your root element ID

const TextModal = ({ isOpen, onRequestClose, onTextSubmit, initialText, field }) => {
    const [text, setText] = useState('');

    useEffect(() => {
        // Initialize text when modal opens
        setText(initialText);
    }, [initialText, isOpen]);

    const handleSubmit = () => {
        onTextSubmit(text);
        onRequestClose();
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Text Input Modal"
            className="textmodal-content"
            overlayClassName="textmodal-overlay"
        >
            <div className="textmodal-header">
                <h2>{field === 'description' ? 'Edit Description' : 'Edit Attachment'}</h2>
            </div>
            <textarea 
                className="textmodal-textarea"
                value={text}
                onChange={(e) => setText(e.target.value)}
                rows="10"
                cols="50"
            />
            <div>
                <button className="textmodal-button" onClick={handleSubmit}>Ok</button>
                <button className="textmodal-button" onClick={onRequestClose}>Cancel</button>
            </div>
        </Modal>
    );
};

export default TextModal;
