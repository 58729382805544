import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Login from './Components/Login';
import Register from './Components/Register';
import Employee from './Components/Employee';
import Admin from './Components/Admin';
import History from './Components/History';
import ProtectedRoute from './Components/ProtectedRoute'; // Import the ProtectedRoute component
import MainManager from './Components/MainManager';
import LeadDashboard from './Components/LeadDashboard'

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/employee" element={
            <ProtectedRoute>
                <Employee />
              </ProtectedRoute>} />
          <Route exact path="/history" element={
              <ProtectedRoute>
                <History />
              </ProtectedRoute>} />
          <Route exact path="/admin" element={ 
            <ProtectedRoute>
                <Admin />
              </ProtectedRoute>} />
          <Route exact path="/main-manager" element={ 
            <ProtectedRoute>
                <MainManager />
              </ProtectedRoute>} />
              <Route exact path="/lead-dashboard" element={ 
            <ProtectedRoute>
                <LeadDashboard />
              </ProtectedRoute>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
