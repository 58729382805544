import React, { useState, useEffect } from 'react';
import './../Styles/ProfileDialog.css'; 
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBriefcase, faEnvelope, faBuilding, faProjectDiagram, faMapMarkerAlt, faUserTie } from '@fortawesome/free-solid-svg-icons';
const apiUrl = process.env.REACT_APP_API_URL;


const ProfileDialog = ({ employeeInfo, onClose, onUpdate }) => {
    const [formData, setFormData] = useState({ ...employeeInfo });
    const [managerName, setManagerName] = useState('');
    const [updateSuccess, setUpdateSuccess] = useState(false); // State for success message


    useEffect(() => {
        const fetchManagerName = async () => {
            const employeeNumber = formData.employee_number;
            console.log('Fetching manager of employee:', employeeNumber);

            if (!employeeNumber) {
                console.error('No employee number available');
                return;
            }

            try {
                const response = await axios.get(`${apiUrl}/api/manager-emp/${employeeNumber}`);
                console.log('Fetched manager data:', response.data);
                setManagerName(response.data.full_name || 'Manager Not Assigned');
            } catch (error) {
                console.error('Error fetching manager name:', error);
                setManagerName('Error fetching manager');
            }
        };

        fetchManagerName();
    }, [formData.employee_number]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     onUpdate(formData);
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${apiUrl}/api/update-user`, formData);

            if (response.status === 200) {
                console.log('User details updated successfully');
                setUpdateSuccess(true); // Show success message
                setTimeout(() => {
                    setUpdateSuccess(false);
                    // onClose(); // Optionally close the dialog after the notification disappears
                }, 5000);
            } else {
                console.error('Failed to update user details');
            }
        } catch (error) {
            console.error('Error updating user details:', error);
        }
    };


    return (
        <div className="profile-dialog-overlay">
            <div className="profile-dialog-content">
            <div className="profile-dialog-header">
                    <h2>My Profile</h2>
                    {/* <img src={formData.profileImage || 'default-profile.png'} alt="Profile" /> */}
                </div>
                {updateSuccess && (
                    <div className="success-message">
                       Your profile updated successfully!
                    </div>
                )}
                <form onSubmit={handleSubmit}>
                    <div className="profile-dialog-grid">
                        <div className="profile-dialog-row">
                            <label htmlFor="fullName">Full Name
                            <FontAwesomeIcon icon={faUser} className="icon" />
                            </label>
                            <input
                                id="fullName"
                                type="text"
                                name="full_name"
                                value={formData.full_name || ''}
                                onChange={handleChange}
                                placeholder="Enter full name"
                            />
                        </div>
                        <div className="profile-dialog-row">
                            <label htmlFor="jobTitle">Job Title
                            <FontAwesomeIcon icon={faBriefcase} className="icon" />
                            </label>
                            <input
                                id="jobTitle"
                                type="text"
                                name="job_title"
                                value={formData.job_title || ''}
                                onChange={handleChange}
                                placeholder="Enter job title"
                            />
                        </div>
                        <div className="profile-dialog-row">
                            <label htmlFor="employeeNumber">Employee ID
                            <FontAwesomeIcon icon={faUserTie} className="icon" />
                            </label>
                            <input
                                id="employeeNumber"
                                type="text"
                                name="employee_number"
                                value={formData.employee_number || ''}
                                onChange={handleChange}
                                placeholder="Enter employee ID"
                                disabled
                            />
                        </div>
                        <div className="profile-dialog-row">
                            <label htmlFor="email">Email
                            <FontAwesomeIcon icon={faEnvelope} className="icon" />
                            </label>
                            <input
                                id="email"
                                type="email"
                                name="username"
                                value={formData.username || ''}
                                onChange={handleChange}
                                placeholder="Enter email"
                                disabled
                            />
                        </div>
                        <div className="profile-dialog-row">
                            <label htmlFor="department">Department
                            <FontAwesomeIcon icon={faBuilding} className="icon" />
                            </label>
                            <input
                                id="department"
                                type="text"
                                name="department"
                                value={formData.department || ''}
                                onChange={handleChange}
                                placeholder="Enter department"
                            />
                        </div>
                        <div className="profile-dialog-row">
                            <label htmlFor="project">Project
                            <FontAwesomeIcon icon={faProjectDiagram} className="icon" />
                            </label>
                            <input
                                id="project"
                                type="text"
                                name="project"
                                value={formData.project_name || ''}
                                onChange={handleChange}
                                placeholder="Enter project"
	                        disabled
                            />
                        </div>
                        <div className="profile-dialog-row">
                            <label htmlFor="location">Location
                            <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />
                            </label>
                            <input
                                id="location"
                                type="text"
                                name="location"
                                value={formData.location || ''}
                                onChange={handleChange}
                                placeholder="Enter location"
                            />
                        </div>
                        <div className="profile-dialog-row">
                            <label htmlFor="reportingTo">Reporting to
                            <FontAwesomeIcon icon={faUserTie} className="icon" />
                            </label>
                            <input
                                id="reportingTo"
                                type="text"
                                value={managerName}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="form-actions">
                        <button type="submit"onClick={handleSubmit}>Update</button>
                        <button type="button" onClick={onClose}>Cancel</button>
                    </div>
                </form>
                
            </div>
        </div>
    );
};

export default ProfileDialog;
