
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './../Styles/History.css'; // Importing CSS for styling
import logo from './../Images/tsqs-hm-ft-logo.gif'; // Importing company logo
import picon from './../Images/profile_icon.png'; // Importing profile icon
import ProfileDialog from './ProfileDialog'; 
import AcknowledgmentButton from './AcknowledgmentButton';
const apiUrl = process.env.REACT_APP_API_URL; // API URL for the backend

const History = () => {
    const [submittedGoals, setSubmittedGoals] = useState([]); // State to store the submitted goals data
    const [isLoading, setIsLoading] = useState(true); // State to track loading status
    const [employeeInfo, setEmployeeInfo] = useState({ full_name: '', job_title: '', employee_number: '' }); // State to store employee info
    const [isProfileDialogOpen, setProfileDialogOpen] = useState(false); // State to handle profile dialog visibility
    const [acknowledgedStatus, setAcknowledgedStatus] = useState({});
    const [goals, setGoals] = useState([]);
    
    // Fetching employee information and submitted goals on component load
    useEffect(() => {
        const fetchEmployeeInfo = async () => {
            const employeeId = localStorage.getItem('employeeId'); // Getting employee ID from local storage
            if (!employeeId) {
                console.error('Employee ID not found in local storage.');
                return;
            }
            try {
                const response = await axios.get(`${apiUrl}/api/employee-info/${employeeId}`); // API call to fetch employee info
                setEmployeeInfo(response.data); // Setting employee info
            } catch (error) {
                console.error('Error fetching employee info:', error);
            }
        };

        const fetchSubmittedGoals = async () => {
            setIsLoading(true); // Setting loading state
            const employeeId = localStorage.getItem('employeeId'); // Getting employee ID from local storage
            if (!employeeId) {
                console.error('Employee ID not found in local storage.');
                setIsLoading(false);
                return;
            }
            try {
                const response = await axios.get(`${apiUrl}/api/submitted-goals/${employeeId}`); // API call to fetch submitted goals
                setSubmittedGoals(response.data); // Setting the submitted goals data
                  // Set acknowledgment statuses
                   // Now fetch the acknowledgment status from the employee_reviews table
                  const quartersResponse = await axios.get(`${apiUrl}/api/available-quarters/${employeeId}`);
                  const quarters = quartersResponse.data; // List of dynamic quarters
        
                 const acknowledgmentStatuses = {};
        
                 // Now fetch acknowledgment statuses per quarter
                 for (const quarter of quarters) {
                      const reviewsResponse = await axios.get(`${apiUrl}/api/employee-reviews/${employeeId}/${quarter}`);
                      const review = reviewsResponse.data[0]; // Assuming one review per quarter
                    
                     
            // Log the entire review object
            console.log(`Full review data for ${quarter}:`, review);
            
            if (review) {
                // Ensure the acknowledged field is correctly accessed and cast to a number
                const acknowledgedValue = parseInt(review.acknowledge, 10); // Parse as integer
                
                // Log the acknowledged value and its type
                console.log(`Acknowledged value for ${quarter}:`, acknowledgedValue, `Type: ${typeof acknowledgedValue}`);
                
                // Check if acknowledgedValue is 1 (since it's a TINYINT)
                if (acknowledgedValue === 1) {
                    acknowledgmentStatuses[quarter] = 1; // Mark as acknowledged
                } else {
                    acknowledgmentStatuses[quarter] = 0; // Default to not acknowledged
                }
            } else {
                acknowledgmentStatuses[quarter] = 0; // Default to 0 if no review found
                console.warn(`No review found for ${quarter}. Defaulting to acknowledgment status 0.`);
            }
        }
        
        setAcknowledgedStatus(acknowledgmentStatuses); // Set acknowledgment statuses in state
        console.log("Acknowledgment statuses: ", acknowledgmentStatuses); // Log for debugging
            } catch (error) {
                console.error('Error fetching submitted goals:', error);
            }
            setIsLoading(false); // Stopping the loading state
        };

        fetchEmployeeInfo(); // Fetching employee info
        fetchSubmittedGoals(); // Fetching submitted goals
    }, []);

    // Function to handle opening of the profile dialog
    const handleOpenProfileDialog = () => {
        setProfileDialogOpen(true); // Setting the profile dialog state to true (open)
    };

    // Function to handle closing of the profile dialog
    const handleCloseProfileDialog = () => {
        setProfileDialogOpen(false); // Setting the profile dialog state to false (close)
    };

     // Update acknowledgment status locally when acknowledgment is made
     const handleAcknowledged = (quarter) => {
        setAcknowledgedStatus((prevStatus) => ({
            ...prevStatus,
            [quarter]: true,
        }));
    };

    const updateAcknowledgedState = (goalId) => {
        setAcknowledgedStatus((prevStatus) => ({
            ...prevStatus,
            [goalId]: true, // Mark this quarter as acknowledged
        }));
    };
    

    // Function to group goals by quarter and render them as horizontal cards
    const renderQuarterCards = () => {
        const quarterGroups = submittedGoals.reduce((acc, goal) => {
            const quarter = goal.quarter;
            if (!acc[quarter]) {
                acc[quarter] = {
                    goals: [],
                    reviewer_comment: goal.reviewer_comment, // Storing the reviewer comment for the quarter
                    final_rating: goal.final_rating,// Storing the final rating for the quarter
                    reviewer_rating: goal.reviewer_rating 
                };
            }
            acc[quarter].goals.push(goal); // Adding goals to the respective quarter
            return acc;
        }, {});

        return (
            <div className="quarter-cards-container">
                {Object.keys(quarterGroups).map((quarter) => {
                    const { goals, reviewer_comment, final_rating, reviewer_rating } = quarterGroups[quarter];

                    return (
                        <div key={quarter} className="quarter-card">
                            <h2>{quarter}</h2> {/* Displaying the quarter */}
                            
                            {/* Table displaying the list of goals for the quarter */}
                            <table className="history-table">
                                <thead>
                                    <tr>
                                        <th>Goal Description</th>
                                        <th>Self Assessment</th>
                                        <th>Attachments</th>
                                        <th>Communicated</th>
                                        <th>Self Rating</th>
                                        <th>Reviewer Rating</th>
                                        <th>Date Submitted</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {goals.map((goal) => (
                                        <tr key={goal.id}>
                                            <td>{goal.goal_description}</td>
                                            <td>{goal.self_assessment}</td>
                                            <td>
                                                {goal.attachments ? (
                                                    <a href={goal.attachments} target="_blank" rel="noopener noreferrer">
                                                        View Attachment
                                                    </a>
                                                ) : 'No Attachment'}
                                            </td>
                                            <td>{goal.communicated ? 'Yes' : 'No'}</td>
                                            <td>{goal.self_rating}</td>
                                            <td>{goal.reviewer_rating}</td>
                                            <td>{new Date(goal.submitted_at).toLocaleString()}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                           
                        {/* This section aligns the Final Rating, Reviewer Comment, and buttons in the same row */}
                        <div className="quarter-review-section">
                            <div className="quarter-review-details">
                                <div className="rating-badge final-rating">
                                    <span>Final Rating: {final_rating}</span>
                                </div>
                                <div className="reviewer-comment-box">
                                    <strong>Reviewer Comment: </strong>{reviewer_comment}
                                </div>
                            </div>

                            {/* Acknowledgment and Download buttons */}
                            <div className="quarter-buttons">
                            <AcknowledgmentButton
                                employeeId={employeeInfo.employee_number}
                                goalId={goals[0].id}
                                reviewerComment={reviewer_comment}
                                finalRating={final_rating}
                                acknowledged={acknowledgedStatus[quarter] === 1} //// Check for acknowledgment status (1 or 0)
                                quarter={quarter}
                                onAcknowledged={(goalId) => updateAcknowledgedState(goalId)}
                             />
                                {/* <button className="acknowledgement-button">Acknowledge</button> */}
                                {/* <button className="download-button">Download</button> */}
                            </div>
                        </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <div className="history-container">
            <header className="history-header">
                <div className="header-content">
                    <img src={logo} alt="Company Logo" className="header-logo" /> {/* Company logo */}
                    <h1 className="history-header-title">SUBMITTED GOALS</h1> {/* Header title */}
                    <div className="employee-info">
                        <img src={picon} alt="Profile Icon" className="profileicon" onClick={handleOpenProfileDialog} /> {/* Profile icon */}
                        <div className="employee-details" onClick={handleOpenProfileDialog}>
                            <span className="employee-name">{employeeInfo.full_name}</span> {/* Employee name */}
                            <span className="employee-job">{employeeInfo.job_title}</span> {/* Employee job title */}
                        </div>
                    </div>
                </div>
            </header>

            <main className="history-body">
                {isLoading ? (
                    <p>Loading...</p> // Show loading state
                ) : (
                    renderQuarterCards() // Render quarter cards
                )}
            </main>

            {isProfileDialogOpen && (
                <ProfileDialog
                    employeeInfo={employeeInfo}
                    onClose={handleCloseProfileDialog}
                    
                />
            )}
        </div>
    );
};

export default History;
