import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import './../Styles/Login.css';
import logo from './../Images/tsqs-hm-ft-logo.gif';


const Login = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginStatus, setLoginStatus] = useState('');
  const navigate = useNavigate();
  
  const handleLogin = (e) => {
    e.preventDefault();
    axios.post(`${apiUrl}/login`, { username, password })
      .then(response => {
        console.log(response.data);
        const { role, employeeId, isMainManager } = response.data;
        setLoginStatus('Login successful');
        localStorage.setItem('employeeId', employeeId); // Store employeeId in local storage
        // Debug logs to verify conditions
         console.log('Role:', role);
         console.log('isMainManager:', isMainManager);

        // Redirect logic based on role and is_main_manager
        if (role === 'admin' && isMainManager === 1) {
          console.log('Navigating to MainManager');
          navigate('/main-manager');  // Redirect to MainManager dashboard
        } else if (role === 'admin') {
          console.log('Navigating to Manager');
          navigate('/admin');
        } 
        else if (role === 'lead') {
          console.log('navigatig to leaddashboard');
          navigate('/lead-dashboard');
        }else {
          navigate('/employee');
        }
      })
      .catch(error => {
        console.error(error);
        if (error.response) {
          setLoginStatus(`Login failed: ${error.response.data}`);
        } else if (error.request) {
          setLoginStatus('Login failed: No response from server');
        } else {
          setLoginStatus(`Login failed: ${error.message}`);
        }
      });
  };

  useEffect(() => {
    if (loginStatus) {
      const timer = setTimeout(() => {
        setLoginStatus('');
      }, 5000); // Close the notification after 5 seconds

      return () => clearTimeout(timer); // Cleanup the timer on unmount
    }
  }, [loginStatus]);

  return (
    <div className="Login-page">
      <div className="Header">
        <img src={logo} alt="Company Logo" className="Logo" />
      </div>
      <div className="Header1">
        <h1 className="Main-heading">PERFORMANCE MANAGEMENT SYSTEM</h1>
      </div>
      <div className="Login">
        <h2>LOGIN</h2>
        {loginStatus && (
           <div className="Notification-overlay">
          <p className={`login-status ${loginStatus.includes('successful') ? 'success' : 'error'}`}>{loginStatus}</p>
          </div>
          )}
        <form onSubmit={handleLogin}>
          <div >
            <div className="login-emaillogin">
            <label>Email:</label>
            <input
              type="email"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            </div>
          </div>
          <div>
            <label>Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button type="submit" className="login-btn">Login</button>
        </form>
        <p className="login-p">
          Don't have an account? <Link to="/register" style={{ color: 'orange' }}>Register here</Link>
        </p>
      </div>
    </div>
  );
}

export default Login;
