import React, { useState, useEffect } from 'react';
import './../../Styles/leadstyles/ConvertToLead.css';
const apiUrl = process.env.REACT_APP_API_URL;

const ConvertToLead = ({ managerId }) => {
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState(''); // 'success' or 'error'

  // Fetch employees under the manager
  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/get-employees?managerId=${managerId}`);
        const data = await response.json();
        setEmployees(data);
      } catch (error) {
        console.error('Error fetching employees:', error);
        setMessage('Failed to load employees.');
        setMessageType('error');
       
      } finally {
        setLoading(false);
      }
    };

    fetchEmployees();
  }, [managerId]);

  // Handle form submission to convert employee to lead
  const handleConvertToLead = async (e) => {
    e.preventDefault();

    if (!selectedEmployee) {
      setMessage('Please select an employee.');
      setMessageType('error');
      showMessage();
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/api/convert-employee-to-lead`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ employeeNumber: selectedEmployee }),
      });

      const result = await response.json();
      if (response.ok) {
        setMessage(`Employee ${selectedEmployee} converted to lead successfully.`);
        setMessageType('success');
        showMessage();
      } else {
        setMessage(result.message || 'Failed to convert employee.');
      }
    } catch (error) {
      console.error('Error converting employee:', error);
      setMessage('Failed to convert employee.');
      setMessageType('error');
      showMessage();
    }
  };

    // Function to show message for a few seconds
    const showMessage = () => {
        setTimeout(() => {
          setMessage('');
        }, 3000); // Message disappears after 3 seconds
      };
    

  return (
    <div className="convert-to-lead-container">
        {/* Display message */}
      {message && (
        <div className={`message ${messageType}`}>
          {message}
        </div>
      )}
      <h4>Convert Employee to Lead</h4>
      <select
        value={selectedEmployee}
        onChange={(e) => setSelectedEmployee(e.target.value)}
      >
        <option value="">Select an Employee</option>
        {employees.map((employee) => (
          <option key={employee.employee_number} value={employee.employee_number}>
            {employee.full_name} - {employee.job_title}
          </option>
        ))}
      </select>
      <button onClick={handleConvertToLead}>Convert to Lead</button>


      

    </div>
  );  
};

export default ConvertToLead;
