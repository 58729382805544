import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const employeeId = localStorage.getItem('employeeId'); // Check if employeeId is stored in localStorage

  if (!employeeId) {
    // If employeeId is not found, redirect to the login page
    return <Navigate to="/" />;
  }

  // If employeeId is found, allow access to the child component
  return children;
};

export default ProtectedRoute;
