import React, { useState, useEffect } from 'react';
import './../../Styles/AdminSectionstyles/AssignProject.css';

const apiUrl = process.env.REACT_APP_API_URL;

function AssignProject() {
  const [subManagers, setSubManagers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedManager, setSelectedManager] = useState('');
  const [selectedProject, setSelectedProject] = useState('');
  const [statusMessage, setStatusMessage] = useState('');

  // Fetch Sub-Managers and Projects on component mount
  useEffect(() => {
    const fetchSubManagers = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/sub-managers`);
        if (!response.ok) {
          throw new Error('Failed to fetch sub-managers');
        }
        const data = await response.json();
        setSubManagers(data);
      } catch (error) {
        console.error('Error fetching sub-managers:', error);
      }
    };

    const fetchProjects = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/fetch-projects`);
        if (!response.ok) {
          throw new Error('Failed to fetch projects');
        }
        const data = await response.json();
        setProjects(data); // Ensure that the project IDs are correctly stored
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    fetchSubManagers();
    fetchProjects();
  }, []);

  // Handle Assign Project
  const handleAssignProject = async (e) => {
    e.preventDefault();

    // Check if both manager and project are selected
    if (!selectedProject || !selectedManager) {
      setStatusMessage('Please select both a project and a manager.');
      return;
    }

    // Prepare data to send to the API
    const assignmentData = {
      project_id: selectedProject, // Correctly using project_id from dropdown
      manager_id: selectedManager, // Correctly using manager_id from dropdown
    };

    // Log the data being sent to the API for debugging
    console.log('Sending data to API:', assignmentData);

    try {
      const response = await fetch(`${apiUrl}/api/assign-project`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(assignmentData),
      });

      if (response.ok) {
        setStatusMessage('Project assigned successfully!');
        setSelectedManager(''); // Reset manager selection
        setSelectedProject(''); // Reset project selection

        setTimeout(() => {
          setStatusMessage('');
        }, 5000);
      } else {
        const errorData = await response.json();
        setStatusMessage(`Error: ${errorData.error}`);
      }
    } catch (error) {
      console.error('Error assigning project:', error);
      setStatusMessage('An error occurred. Please try again.');
    }
  };

  return (
    <div className="assign-project-container">
      <h2>Assign Project to Manager</h2>
      <form onSubmit={handleAssignProject} className="assign-project-form">
        <div className="assign-project-form-group">
          <label className="assignproject" htmlFor="managerSelect">Select Manager:</label>
          <select
            id="managerSelect"
            value={selectedManager}
            onChange={(e) => setSelectedManager(e.target.value)}
            required
          >
            <option value="">-- Select a Manager --</option>
            {subManagers.map((manager) => (
              <option key={manager.id} value={manager.employee_number}>
                {manager.full_name}
              </option>
            ))}
          </select>
        </div>
        <div className="assign-project-form-group">
          <label className="assignproject" htmlFor="projectSelect">Select Project:</label>
          <select
            id="projectSelect"
            value={selectedProject}
            onChange={(e) => setSelectedProject(e.target.value)}
            required
          >
            <option value="">-- Select a Project --</option>
            {projects.map((project) => (
              <option key={project.id} value={project.id}>
                {project.project_name} {/* Make sure this is correct */}
              </option>
            ))}
          </select>
        </div>
        <button type="submit" className="assign-project-button">
          Assign Project
        </button>
      </form>
      {statusMessage && <p className="assign-project-status-message-overlay">{statusMessage}</p>}
    </div>
  );
}

export default AssignProject;
