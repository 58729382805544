import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './../Styles/AssignGoal.css';
const apiUrl = process.env.REACT_APP_API_URL;

const AssignGoal = ({ onClose }) => {
    const [goals, setGoals] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [selectedGoals, setSelectedGoals] = useState([]);
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [goalsDropdownOpen, setGoalsDropdownOpen] = useState(false);
    const [employeesDropdownOpen, setEmployeesDropdownOpen] = useState(false);
    const [showUpdateMessage, setShowUpdateMessage] = useState(false);
    const goalsDropdownRef = useRef(null);
    const employeesDropdownRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false); // Loading state

    useEffect(() => {
        const fetchData = async () => {
            try {
                const goalsResponse = await axios.get(`${apiUrl}/api/goals`);
                console.log('Fetched goals:', goalsResponse.data);  // Debugging
                setGoals(goalsResponse.data);
                const employeesResponse = await axios.get(`${apiUrl}/api/employees`);
                console.log('Fetched employees:', employeesResponse.data);  // Debugging
                setEmployees(employeesResponse.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (goalsDropdownRef.current && !goalsDropdownRef.current.contains(event.target)) {
                setGoalsDropdownOpen(false);
            }
            if (employeesDropdownRef.current && !employeesDropdownRef.current.contains(event.target)) {
                setEmployeesDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleCheckboxChange = (setStateFunction, value) => {
        setStateFunction(prev => {
            if (prev.includes(value)) {
                return prev.filter(item => item !== value);
            } else {
                return [...prev, value];
            }
        });
    };

    const handleAssignGoal = async () => {
        if (selectedGoals.length === 0 || selectedEmployees.length === 0) {
            alert('Please select at least one goal and one employee.');
            // setStatusMessage('Please select at least one goal and one employee.');
            // setShowStatusModal(true);
            return;
        }
        setIsLoading(true); // Start loading
        try {
            console.log('Assigning goals:', selectedGoals, 'to employees:', selectedEmployees);
            await axios.post(`${apiUrl}/api/assign-goal`, {
                goalIds: selectedGoals,
                employeeIds: selectedEmployees
            });
              // Show update message and hide it after 10 seconds
              setShowUpdateMessage(true);
              setTimeout(() => {
                  setShowUpdateMessage(false);
              }, 10000);
        } catch (error) {
            console.error('Error assigning goals:', error);
           alert('Error assigning goals');
        } finally {
            setIsLoading(false); // Stop loading
        }
    };
   
    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Assign Goals</h2>
                <div className="form-group">
                    <label className="assign-goallabel">Select Goals:</label>
                    <div className="assign-dropdown-container" ref={goalsDropdownRef}>
                        <button
                            className="assign-dropdown-button"
                            onClick={() => setGoalsDropdownOpen(prev => !prev)}
                        >
                            {selectedGoals.length === 0 ? 'Select Goals' : `Selected Goals (${selectedGoals.length})`}
                        </button>
                        {goalsDropdownOpen && (
                            <div className="assign-dropdown-menu">
                                {goals.map(goal => (
                                    <div key={goal.id} className="checkbox-item">
                                        <input
                                            type="checkbox"
                                            id={`goal-${goal.id}`}
                                            checked={selectedGoals.includes(goal.id)}
                                            onChange={() => handleCheckboxChange(setSelectedGoals, goal.id)}
                                        />
                                        <label htmlFor={`goal-${goal.id}`}>{goal.goal_description}</label>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                <div className="form-group">
                    <label className="assign-goallabel" style={{color:'#333'}}>Select Employees:</label>
                    <div className="dropdown-container" ref={employeesDropdownRef}>
                        <button
                            className="dropdown-button"
                            onClick={() => setEmployeesDropdownOpen(prev => !prev)}
                        >
                            {selectedEmployees.length === 0 ? 'Select Employees' : `Selected Employees (${selectedEmployees.length})`}
                        </button>
                        {employeesDropdownOpen && (
                            <div className="dropdown-menu">
                                {employees.map(employee => (
                                    <div key={employee.employee_number} className="checkbox-item">
                                        <input
                                            type="checkbox"
                                            id={`employee-${employee.employee_number}`}
                                            checked={selectedEmployees.includes(employee.employee_number)}
                                            onChange={() => handleCheckboxChange(setSelectedEmployees, employee.employee_number)}
                                        />
                                        <label htmlFor={`employee-${employee.employee_number}`}>{employee.full_name}</label>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                <div className="assigngoal-button-group">
                    {/* <button onClick={handleAssignGoal}  disabled={isLoading} className="assigngoal-assign-button">Assign</button> */}
                    <button
                        onClick={handleAssignGoal}
                        disabled={isLoading}
                        className="assigngoal-assign-button"
                    >
                        {isLoading ? (
                            <span>
                                <i
                                    className="fa fa-spinner fa-spin"
                                    style={{ marginRight: '8px' }}
                                ></i>
                                Assigning...
                            </span>
                        ) : (
                            'Assign'
                        )}
                    </button>
                    <button  onClick={onClose} className="assigngoalclose-button">Close</button>
                </div>
                {isLoading ? (
                    <span>
                        <i className="fa fa-spinner fa-spin" style={{ marginRight: '8px' }}></i>
                        Assigning...
                    </span>
                ) : (
                    'Assign Goals'
                )}
                {isLoading && (
                    <div className="loading-overlay">
                        <i className="fa fa-spinner fa-spin loading-spinner"></i>
                    </div>
                )}
                   {showUpdateMessage && (
                    <div className="update-message">
                        Goals assigned successfully!
                    </div>
                )}
            </div>
        </div>
    );
};

export default AssignGoal;




